<template>
  <div class="video-categorization">
    <div class="modal fade" id="deleteRecordModal" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              Confirm you would like to delete this related video!
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>Are you sure you would like to delete this related video?</p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              @click="saveDeleteRecord"
            >
              Yes
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              No
            </button>
          </div>
        </div>
      </div>
    </div>
    <table class="table-striped table table-hover table-condensed">
      <thead>
        <tr>
          <th>Video</th>
          <th>Related Video</th>
          <th>
            <router-link :to="{ path: '/related-videos/add' }"
              ><button class="btn btn-primary pull-right">
                Add
              </button></router-link
            >
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(video, index) in related" v-bind:key="video.id">
          <td>{{ video.parent_video_title }}</td>
          <td>{{ video.child_video_title }}</td>
          <td style="width: 65px">
            <button
              class="btn btn-danger btn-xs"
              @click="
                confirmDeleteRecord(
                  $event,
                  video.video_id,
                  video.related_video_id,
                  index
                )
              "
            >
              Delete
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<style></style>

<script>
import VideoRelatedService from "../../services/VideoRelatedService";

export default {
  data: function () {
    return {
      related: [],
      deleteRecord: {
        index: 0,
        video_id: 0,
        related_video_id: 0,
      },
    };
  },

  created: function () {
    VideoRelatedService.all().then((response) => {
      this.related = response.data;
    });
  },

  methods: {
    confirmDeleteRecord: function (event, videoId, relatedVideoId, index) {
      this.deleteRecord = {
        video_id: videoId,
        related_video_id: relatedVideoId,
        index: index,
      };

      $("#deleteRecordModal").modal({});
    },

    saveDeleteRecord: function () {
      VideoRelatedService.delete(
        this.deleteRecord.video_id,
        this.deleteRecord.related_video_id
      ).then((response) => {
        $("[data-dismiss=modal]").trigger({ type: "click" });
        this.related.splice(this.deleteRecord.index, 1);
      });
    },
  },
};
</script>
