<template>
  <div class="tipForm" v-on:submit.prevent="saveChanges">
    <form class="form-horizontal">
      <div class="row">
        <label for="trial_workout">Featured:</label>
        <div class="form-check">
          <input type="radio" class="form-check-input" name="featured" id="tip_workout_yes" value="1"
            v-model="tip.featured" />
          <label for="featured_media_type_video" class="form-check-label">Yes</label>
        </div>
        <div class="form-check" style="margin-left: 15px">
          <input type="radio" class="form-check-input" name="featured" id="tip_workout_no" value="0"
            v-model="tip.featured" />
          <label for="featured_media_type_image" class="form-check-label">No</label>
        </div>
      </div>
      <div class="form-group">
        <label for="title">Title:</label>
        <input type="text" name="title" v-model="tip.title" placeholder="Title" class="form-control" />
      </div>
      <div class="form-group">
        <label for="metaTitle">Meta Title (SEO):</label>
        <input type="text" v-model="tip.meta_title" class="form-control" name="metaTitle" id="metaTitle" placeholder="" />
        <div class="invalid-feedback">
          Please provide a valid meta title.
        </div>
      </div>

      <div class="form-group">
        <label for="metaDescription">Meta Description (SEO):</label>
        <textarea v-model="tip.meta_description" class="form-control" name="metaDescription" id="metaDescription"
          placeholder=""></textarea>
        <div class="invalid-feedback">
          Please provide a valid meta description.
        </div>
      </div>

      <div class="form-group">
        <div class="col-xs-5">
          <div class="form-group">
            <label for="VideoURL">Video URL:</label>
            <input type="text" name="title" v-model="tip.video_url" v-on:blur="videoUrlChanged()"
              placeholder="Full tip video url" class="input-inline form-control" />
          </div>
        </div>
        <div class="col-xs-4 col-md-push-1">
          <div class="form-group">
            <label for="image">Thumbnail:</label>
            <input type="text" placeholder="Thumbnail URL" name="thumbnail" v-model="tip.thumbnail"
              class="input-inline form-control" />
            <img :src="tip.thumbnail" alt="Thumbnail Link" />
            <div v-show="tip.video_url && tip.video_url.length> 0">
              <button class="btn btn-primary" @click="uploadThumbnails">Upload Thumbnails</button>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label for="body">Body:</label>
        <editor api-key="h6ymu878nq2dmit84wddqdm84to1x90pryg1iec6s96rph86" name="body" id="body" v-model="tip.body" class="wysiwyg-editor" :init="{
          plugins:
            'wordcount link image fullscreen lists textcolor colorpicker',
        }"></editor>
      </div>
      <div class="form-group">
        <label for="body">Release Date:</label>
        <datepicker placeholder="Release Date" v-model="tip.release_date" name="release_date" input-class="form-control">
        </datepicker>
      </div>

      <div class="form-group signup-carousel-buttons">
        <button class="btn btn-primary">Save</button>
      </div>
    </form>
  </div>
</template>

<style></style>

<script>
import TipService from "../../services/TipService";
import ThumbnailService from "../../services/ThumbnailService";
import Datepicker from "vuejs-datepicker";
import Editor from "@tinymce/tinymce-vue";

export default {
  data: function () {
    return {
      tip: {
        featured: 0,
        thumbnail: null
      }
    };
  },

  created() {
    if (!isNaN(this.$route.params.id)) {
      TipService.findById(this.$route.params.id).then((response) => {
        let data = response.data;

        this.tip = {
          title: data.title,
          video_url: data.video_url,
          thumbnail: data.thumbnail,
          body: data.body,
          release_date: data.release_date,
          meta_title: data.meta_title,
          meta_description: data.meta_description,
          featured: data.featured
        };
      });
    } else if (this.$route.params.id !== "add") {
      alert("Invalid parameter.");
    }
  },

  components: {
    editor: Editor,
    datepicker: Datepicker,
  },

  methods: {
    saveChanges: function () {
      let tip = {
          title: this.tip.title || "",
          video_url: this.tip.video_url || "",
          thumbnail: this.tip.thumbnail || "",
          body: this.tip.body || "",
          release_date: new Date(this.tip.release_date || ""),
          meta_title: this.tip.meta_title || "",
          meta_description: this.tip.meta_description || "",
          featured: this.tip.featured || 0
        };

      if (!isNaN(this.$route.params.id)) {
        TipService.update(this.$route.params.id, tip).then((response) => {
          alert("Post Updated");
        });
      } else if (this.$route.params.id == "add") {
        TipService.create(tip).then((response) => {
          alert("Post Created");
        });
      } else {
        alert("invalid");
      }
    },

    uploadThumbnails: function() {
      ThumbnailService.uploadThumbnailsToS3ForVimeoEmbedLink(this.tip.video_url).then(response => {
        alert("Successfully uploaded thumbnails");
      }).catch(err => {
        alert("An error occurred");
      });
      
    },

    videoUrlChanged: function () {
      if (this.tip.video_url.length > 0) {
        let vimeoVideoId = this.tip.video_url.split("/").pop();

        if (vimeoVideoId) {
          this.axios
            .get(
              "https://vimeo.com/api/oembed.json?url=http%3A//vimeo.com/" +
              vimeoVideoId
            )
            .then((response) => {
              let thumbnailLink = response.data.thumbnail_url.replace('.webp', '').split('_');

              let thumbnailLinkLowRes = thumbnailLink[0] + '_300.jpg';

              this.tip.thumbnail = 'https://d18cfrkvlsavt2.cloudfront.net/thumbnails/' + thumbnailLinkLowRes.replace('.jpg', '').split('/').reverse()[0];

            });
        }
      }
    },
  },
};
</script>
