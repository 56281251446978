var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"video-categorization"},[_c('div',{staticClass:"modal fade",attrs:{"id":"deleteRecordModal","tabindex":"-1","role":"dialog"}},[_c('div',{staticClass:"modal-dialog",attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"modal-footer"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":_vm.saveDeleteRecord}},[_vm._v(" Yes ")]),_c('button',{staticClass:"btn btn-secondary",attrs:{"type":"button","data-dismiss":"modal"}},[_vm._v(" No ")])])])])]),_c('table',{staticClass:"table-striped table table-hover table-condensed"},[_c('thead',[_c('tr',[_c('th',[_vm._v("Video")]),_c('th',[_vm._v("Related Video")]),_c('th',[_c('router-link',{attrs:{"to":{ path: '/related-videos/add' }}},[_c('button',{staticClass:"btn btn-primary pull-right"},[_vm._v(" Add ")])])],1)])]),_c('tbody',_vm._l((_vm.related),function(video,index){return _c('tr',{key:video.id},[_c('td',[_vm._v(_vm._s(video.parent_video_title))]),_c('td',[_vm._v(_vm._s(video.child_video_title))]),_c('td',{staticStyle:{"width":"65px"}},[_c('button',{staticClass:"btn btn-danger btn-xs",on:{"click":function($event){return _vm.confirmDeleteRecord(
                $event,
                video.video_id,
                video.related_video_id,
                index
              )}}},[_vm._v(" Delete ")])])])}),0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-header"},[_c('h5',{staticClass:"modal-title"},[_vm._v(" Confirm you would like to delete this related video! ")]),_c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-body"},[_c('p',[_vm._v("Are you sure you would like to delete this related video?")])])
}]

export { render, staticRenderFns }