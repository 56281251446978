<template>
  <div class="layout">
    <PageHead></PageHead>
    <div class="container-fluid">
      <div class="row">
        <PageSidebar></PageSidebar>

        <main role="main" class="col-md-9 ml-sm-auto col-lg-10 px-4">
          <router-view></router-view>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import PageHead from "../components/PageHead";
import PageSidebar from "../components/PageSidebar";

export default {
  components: {
    PageHead,
    PageSidebar,
  },
};
</script>
<style></style>
