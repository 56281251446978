import Vue from "vue";
import AccountService from "./AccountService";

export default {
  update(id, page) {
    let apiMethodUrl = process.env.VUE_APP_ROOT_API + "/page/update";
    let pagePayload = {};
    let options = AccountService.getSessionHeaders();
    pagePayload.description = page.description;
    pagePayload.content = page.content;
    pagePayload.id = id;

    return Vue.axios.post(apiMethodUrl, pagePayload, options);
  },
  findById(id) {
    let apiMethodUrl = process.env.VUE_APP_ROOT_API + "/page/id/" + id;
    return Vue.axios.get(apiMethodUrl);
  },
};
