import Vue from "vue";
import AccountService from "./AccountService";

export default {
  create(category) {
    let apiMethodUrl = process.env.VUE_APP_ROOT_API + "/videos/categories";
    let options = AccountService.getSessionHeaders();
    let categoryPayload = {
      ...category,
    };

    categoryPayload.sort_order = parseInt(categoryPayload.sort_order);
    if (categoryPayload.parent === "") {
      categoryPayload.parent = null;
    }

    return Vue.axios.post(apiMethodUrl, categoryPayload, options);
  },

  update(id, category) {
    let apiMethodUrl = process.env.VUE_APP_ROOT_API + "/videos/categories/" + id;
    let options = AccountService.getSessionHeaders();
    let categoryPayload = {
      ...category
    };

    categoryPayload.sort_order = parseInt(categoryPayload.sort_order);

    if (categoryPayload.parent === "") {
      categoryPayload.parent = null;
    }

    if (categoryPayload.parent === "") {
      categoryPayload.parent = null;
    }

    return Vue.axios.patch(apiMethodUrl, categoryPayload, options);
  },

  delete(id) {
    let apiMethodUrl = process.env.VUE_APP_ROOT_API + "/videos/categories/" + id;
    let options = AccountService.getSessionHeaders();

    return Vue.axios.delete(apiMethodUrl, options);
  },

  findById(id) {
    let apiMethodUrl = process.env.VUE_APP_ROOT_API + "/videos/categories/" + id;
    let options = AccountService.getSessionHeaders();
    return Vue.axios.get(apiMethodUrl, options);
  },

  all() {
    let apiMethodUrl = process.env.VUE_APP_ROOT_API + "/videos/categories";
    let options = AccountService.getSessionHeaders();
    return Vue.axios.get(apiMethodUrl, options);
  },
};
