<template>
  <div class="notes">
    <div class="modal fade" id="deleteRecordModal" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              Confirm you would like to delete this note!
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>Are you sure you would like to delete this note?</p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              @click="saveDeleteRecord"
            >
              Yes
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              No
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row header">
      <div class="col-xs-6 col-md-6 header-group">
        <h2>Notes</h2>
      </div>
      <div class="col-xs-6 col-md-6 search">
        <input
          class="form-control form-control-sm w-100"
          type="text"
          placeholder="Search"
          aria-label="Search"
          v-model="search"
        />
      </div>
    </div>
    <table class="table-striped table table-hover table-condensed">
      <tr>
        <th>Title</th>
        <th>
          <router-link to="/notes/add"
            ><button class="btn btn-primary pull-right">
              Add
            </button></router-link
          >
        </th>
      </tr>
      <tr v-for="(note, index) in filteredNotes" v-bind:key="note.id">
        <td>{{ note.title }}</td>
        <td style="width: 350px">
          <router-link :to="{ path: '/notes/' + note.id }"
            ><button class="btn btn-success btn-xs">Edit</button></router-link
          >
          <button
            class="btn btn-primary btn-xs"
            @click="downloadPDF($event, note.id)"
          >
            Download
          </button>
          <router-link :to="{ path: '/notes/preview/' + note.id }"
            ><button class="btn btn-primary btn-xs">
              Preview
            </button></router-link
          >
          <button
            class="btn btn-danger btn-xs"
            @click="confirmDeleteRecord($event, note.id, index)"
          >
            Delete
          </button>
        </td>
      </tr>
    </table>
  </div>
</template>

<style></style>

<script>
import NoteService from "../../services/NoteService";
import NotesFormVue from "./NotesForm.vue";

export default {
  data: function () {
    return {
      notes: [],
      deleteRecord: {
        index: 0,
        id: 0,
      },
      search: "",
    };
  },

  created: function () {
    NoteService.all().then((response) => {
      this.notes = response.data;
    });
  },

  methods: {
    downloadPDF: function (event, id) {
      NoteService.download(id).then((response) => {
        let blob = new Blob([response.data], { type: "application/pdf" });

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob);
          return;
        }

        const data = window.URL.createObjectURL(blob);
        let link = document.createElement("a");
        link.href = data;
        link.download = "file.pdf";
        link.dispatchEvent(
          new MouseEvent(`click`, {
            bubbles: true,
            cancelable: true,
            view: window,
          })
        );

        link.remove();

        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data);
        }, 100);
      });
    },

    confirmDeleteRecord: function (event, id, index) {
      this.deleteRecord = {
        id: id,
        index: index,
      };

      $("#deleteRecordModal").modal({});
    },

    saveDeleteRecord: function () {
      NoteService.delete(this.deleteRecord.id).then((response) => {
        console.log(response);
        //$('#deleteRecordModal').modal('hide');
        $("[data-dismiss=modal]").trigger({ type: "click" });
        this.notes.splice(this.deleteRecord.index, 1);
      });
    },
  },

  computed: {
    filteredNotes() {
      return this.notes.filter((item) => {
        let searchPhrase = this.search.toLowerCase();

        return item.title.toLowerCase().indexOf(searchPhrase) > -1;
      });
    },
  },
};
</script>
