<template>
  <div class="tips">
    <div class="modal fade" id="deleteRecordModal" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              Confirm you would like to delete this tip!
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>Are you sure you would like to delete this tip?</p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              @click="saveDeleteRecord"
            >
              Yes
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              No
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row header">
      <div class="col-xs-6 col-md-6 header-group">
        <h2>Tips</h2>
      </div>
      <div class="col-xs-6 col-md-6 search">
        <input
          class="form-control form-control-sm w-100"
          type="text"
          placeholder="Search"
          aria-label="Search"
          v-model="search"
        />
      </div>
    </div>
    
    <table class="table-striped table table-hover table-condensed">
      <tr>
        <th>&nbsp;</th>
        <th>Title</th>
        <th>Date Released</th>
        <th>
          <router-link :to="{ path: '/tips/add' }"
            ><button class="btn btn-primary pull-right">
              Add
            </button></router-link
          >
        </th>
      </tr>
      <tr v-for="(tip, index) in filteredTips" v-bind:key="tip.id">
        <td><img :src="tip.thumbnail" width="25%" height="25%" /></td>
        <td>{{ tip.title }}</td>
        <td>{{ tip.release_date }}</td>
        <td style="width: 200px">
          <router-link :to="{ path: '/tips/' + tip.id }"
            ><button class="btn btn-success btn-xs">Edit</button></router-link
          >
          <button
            class="btn btn-danger btn-xs"
            @click="confirmDeleteRecord($event, tip.id, index)"
          >
            Delete
          </button>
        </td>
      </tr>
    </table>
  </div>
</template>

<style></style>

<script>
import TipService from "../../services/TipService";

export default {
  data: function () {
    return {
      tips: [],
      deleteRecord: {
        index: 0,
        id: 0,
      },
      search: "",
    };
  },

  created: function () {
    TipService.all().then((response) => {
      this.tips = response.data;
    });
  },

  methods: {
    confirmDeleteRecord: function (event, id, index) {
      this.deleteRecord = {
        id: id,
        index: index,
      };

      $("#deleteRecordModal").modal({});
    },

    saveDeleteRecord: function () {
      TipService.delete(this.deleteRecord.id).then((response) => {
        console.log(response);
        //$('#deleteRecordModal').modal('hide');
        $("[data-dismiss=modal]").trigger({ type: "click" });
        this.tips.splice(this.deleteRecord.index, 1);
      });
    },
  },

  computed: {
    filteredTips() {
      return this.tips.filter((item) => {
        let searchPhrase = this.search.toLowerCase();

        return item.title.toLowerCase().indexOf(searchPhrase) > -1;
      });
    },
  },
};
</script>
