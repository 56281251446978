import jquery from "jquery/dist/jquery.js";
window.$ = window.jQuery = jquery;

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import VueAxios from "vue-axios";
import "bootstrap/dist/css/bootstrap.min.css";
import Bootstrap from "bootstrap/dist/js/bootstrap.bundle.js";
import Vue2Storage from "vue2-storage";
import VueCookies from "vue-cookies";
import VeeValidate from "vee-validate";

Vue.use(Vue2Storage, {
  prefix: "fm_",
  driver: "local",
  ttl: 60 * 60 * 24 * 1000,
});

Vue.use(VueCookies);
Vue.use(VeeValidate);
Vue.use(VueAxios, axios);

Vue.config.productionTip = false;

Vue.filter('dateformat', function (value) {
  if (!value) return ''

  const date = new Date(value);
  return date.toLocaleDateString("en-IN", {
    "year": "numeric",
    "month": "long",
    "day": "numeric",
    "timeZone": 'UTC'
    });
});


new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
