<template>
  <div class="video-categorization-form">
    <div
      class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom"
    >
      <h1 class="h2">Assign Video to Category</h1>
    </div>
    <form class="form-horizontal" v-on:submit.prevent="saveChanges">
      <div class="form-group">
        <label for="body">Video:</label>
        <select class="form-control" v-model="assignment.video">
          <option
            v-for="video in videos"
            v-bind:key="video.id"
            :value="video.id"
          >
            {{ video.title }}
          </option>
        </select>
      </div>
      <div class="form-group">
        <label for="body">Instructor:</label>
        <select class="form-control" v-model="assignment.instructor">
          <option
            v-for="instructor in instructors"
            v-bind:key="instructor.id"
            :value="instructor.id"
          >
            {{ instructor.fullname }}
          </option>
        </select>
      </div>
      <div class="form-group signup-carousel-buttons">
        <button class="btn btn-primary">Save</button>
      </div>
    </form>
  </div>
</template>

<style></style>

<script>
import VideoBioService from "../../services/VideoBioService";
import VideoService from "../../services/VideoService";
import BioService from "../../services/BioService";

export default {
  data: function () {
    return {
      videos: [],
      instructors: [],
      assignment: {
        video: 0,
        instructor: 0,
      },
    };
  },

  created: function () {
    VideoService.all().then((response) => {
      this.videos = response.data;
    });

    BioService.all().then((response) => {
      this.instructors = response.data;
    });
  },

  methods: {
    saveChanges: function () {
      if (this.assignment.video == 0 || this.assignment.instructor == 0) {
        alert("Please select both a video and instructor.");
        return;
      }

      let videoInstructorRow = {
        videoId: this.assignment.video,
        instructorId: this.assignment.instructor,
      };

      VideoBioService.create(videoInstructorRow).then((response) => {
        alert("Video categorized.");
      });
    },
  },
};
</script>
