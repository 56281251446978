<template>
  <div class="video-categorization">
    <div class="modal fade" id="deleteRecordModal" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              Confirm you would like to delete this categorization!
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>Are you sure you would like to delete this categorization?</p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              @click="saveDeleteRecord"
            >
              Yes
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              No
            </button>
          </div>
        </div>
      </div>
    </div>
    <table class="table-striped table table-hover table-condensed">
      <thead>
        <tr>
          <th>Instructor</th>
          <th>Video</th>
          <th>
            <router-link :to="{ path: '/instructor-videos/add' }"
              ><button class="btn btn-primary pull-right">
                Add
              </button></router-link
            >
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(categorize, index) in categorizations"
          v-bind:key="categorize.id"
        >
          <td>{{ categorize.fullname }}</td>
          <td>{{ categorize.title }}</td>
          <td style="width: 65px">
            <button
              class="btn btn-danger btn-xs"
              @click="
                confirmDeleteRecord(
                  $event,
                  categorize.video_id,
                  categorize.instructor_id,
                  index
                )
              "
            >
              Delete
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<style></style>

<script>
import VideoBioService from "../../services/VideoBioService";

export default {
  data: function () {
    return {
      categorizations: [],
      deleteRecord: {
        index: 0,
        video_id: 0,
        instructor_id: 0,
      },
    };
  },

  created: function () {
    VideoBioService.all().then((response) => {
      this.categorizations = response.data;
    });
  },

  methods: {
    confirmDeleteRecord: function (event, videoId, instructorId, index) {
      this.deleteRecord = {
        video_id: videoId,
        instructor_id: instructorId,
        index: index,
      };

      $("#deleteRecordModal").modal({});
    },

    saveDeleteRecord: function () {
      VideoBioService.delete(
        this.deleteRecord.video_id,
        this.deleteRecord.instructor_id
      ).then((response) => {
        $("[data-dismiss=modal]").trigger({ type: "click" });
        this.categorizations.splice(this.deleteRecord.index, 1);
      });
    },
  },
};
</script>
