<template>
  <div class="purchases">
    <div class="row header">
      <div class="col-xs-6 col-md-6 header-group">
        <h2>Purchases</h2>
      </div>
      <div class="col-xs-6 col-md-6 search">
        <input
          class="form-control form-control-sm w-100"
          type="text"
          placeholder="Search"
          aria-label="Search"
          v-model="search"
        />
      </div>
    </div>
    <table class="table-striped table table-hover table-condensed">
      <tr>
        <th>Title</th>
        <th>Full Name</th>
        <th>Email</th>
        <th>Country</th>
        <th>Attempts</th>
        <th>Grade</th>
        <th>Date Completed</th>
      </tr>
      <tr v-for="purchase in filteredPurchases" v-bind:key="purchase.id">
        <td>{{ purchase.title }}</td>
        <td>{{ purchase.fullname }}</td>
        <td>{{ purchase.email }}</td>
        <td>{{ purchase.country }}</td>
        <td>{{ purchase.attempts }}</td>
        <td>{{ purchase.grade }}</td>
        <td>{{ purchase.date_completed }}</td>
      </tr>
    </table>
  </div>
</template>

<style></style>

<script>
import PurchaseService from "../../services/PurchaseService";

export default {
  data: function () {
    return {
      search: "",
      purchases: [],
    };
  },

  created: function () {
    PurchaseService.all().then((response) => {
      console.log(response.data);
      this.purchases = response.data;
    });
  },

  computed: {
    filteredPurchases() {
      return this.purchases.filter((item) => {
        let searchPhrase = this.search.toLowerCase();

        return (
          item.fullname.toLowerCase().indexOf(searchPhrase) > -1 ||
          item.email.toLowerCase().indexOf(searchPhrase) > -1
        );
      });
    },
  },
};
</script>
