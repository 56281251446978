import Vue from "vue";
import AccountService from "./AccountService";

export default {
  create(audioRecord) {
    let apiMethodUrl = process.env.VUE_APP_ROOT_API + "/audios";
    let options = AccountService.getSessionHeaders();

    return  Vue.axios.post(apiMethodUrl, audioRecord, options);
  },

  update(id, audioRecord) {
    let apiMethodUrl = process.env.VUE_APP_ROOT_API + "/audios/" + id;
    let options = AccountService.getSessionHeaders();

    return Vue.axios.patch(apiMethodUrl, audioRecord, options);
  },

  delete(id) {
    let apiMethodUrl = process.env.VUE_APP_ROOT_API + "/audios/" + id;
    let options = AccountService.getSessionHeaders();

    return Vue.axios.delete(apiMethodUrl, options);
  },

  findById(id) {
    let apiMethodUrl = process.env.VUE_APP_ROOT_API + "/audios/" + id;
    let options = AccountService.getSessionHeaders();

    return Vue.axios.get(apiMethodUrl, options);
  },

  all() {
    let apiMethodUrl = process.env.VUE_APP_ROOT_API + "/audios";
    let options = AccountService.getSessionHeaders();

    return Vue.axios.get(apiMethodUrl, options);
  },
};
