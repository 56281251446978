import Vue from "vue";
import AccountService from "./AccountService";

export default {
  all() {
    let apiMethodUrl =
      process.env.VUE_APP_ROOT_API + "/assign-video-related";
    let options = AccountService.getSessionHeaders();

    return Vue.axios.get(apiMethodUrl, options);
  },

  create(videoRelated) {
    let apiMethodUrl =
      process.env.VUE_APP_ROOT_API + "/assign-video-related";
    let videoRelatedPayload = {};
    let options = AccountService.getSessionHeaders();
    videoRelatedPayload.video = videoRelated.videoId;
    videoRelatedPayload.related_video = videoRelated.relatedVideoId;

    return Vue.axios.post(apiMethodUrl, videoRelatedPayload, options);
  },

  delete(video, relatedVideoId) {
    let apiMethodUrl =
      process.env.VUE_APP_ROOT_API + "/assign-video-related";
    let options = AccountService.getSessionHeaders();
    options.data = {
      video: video,
      related_video: relatedVideoId
    };

    return Vue.axios.delete(apiMethodUrl, options);
  },
};
