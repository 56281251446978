import Vue from "vue";
import AccountService from "./AccountService";

export default {
  create(quiz) {
    let apiMethodUrl = process.env.VUE_APP_ROOT_API + "/cec/quizzes";
    let quizPayload = {};
    let options = AccountService.getSessionHeaders();

    quizPayload.title = quiz.title;
    quizPayload.content_code = quiz.content_code;
    quizPayload.price = quiz.price;
    quizPayload.number_of_questions = quiz.number_of_questions;
    quizPayload.approved_cecs = quiz.approved_cecs;
    quizPayload.meta_title = quiz.meta_title;
    quizPayload.meta_description = quiz.meta_description;
    quizPayload.course_number = quiz.course_number;
    quizPayload.handout = quiz.handout;
    quizPayload.video = quiz.video;
    quizPayload.description = quiz.description;
    quizPayload.instructions = quiz.instructions;
    quizPayload.release_date = quiz.release_date;

    return Vue.axios.post(apiMethodUrl, quizPayload, options);
  },

  update(id, quiz) {
    let apiMethodUrl = process.env.VUE_APP_ROOT_API + "/cec/quizzes/" + id;
    let quizPayload = {};
    let options = AccountService.getSessionHeaders();

    quizPayload.title = quiz.title;
    quizPayload.content_code = quiz.content_code;
    quizPayload.price = quiz.price;
    quizPayload.number_of_questions = quiz.number_of_questions;
    quizPayload.approved_cecs = quiz.approved_cecs;
    quizPayload.meta_title = quiz.meta_title;
    quizPayload.meta_description = quiz.meta_description;
    quizPayload.course_number = quiz.course_number;
    quizPayload.video = quiz.video;
    quizPayload.handout = quiz.handout;
    quizPayload.description = quiz.description;
    quizPayload.instructions = quiz.instructions;
    quizPayload.release_date = quiz.release_date;

    return Vue.axios.patch(apiMethodUrl, quizPayload, options);
  },

  delete(id) {
    let apiMethodUrl = process.env.VUE_APP_ROOT_API + "/cec/quizzes/" + id;
    let options = AccountService.getSessionHeaders();

    return Vue.axios.delete(apiMethodUrl, quizPayload, options);
  },
  findById(id) {
    let apiMethodUrl = process.env.VUE_APP_ROOT_API + "/cec/quizzes/" + id;
    let options = AccountService.getSessionHeaders();
    return Vue.axios.get(apiMethodUrl, options);
  },

  all() {
    let apiMethodUrl = process.env.VUE_APP_ROOT_API + "/cec/quizzes";
    let options = AccountService.getSessionHeaders();
    return Vue.axios.get(apiMethodUrl, options);
  },
};
