<template>
  <div class="testimonials">
    <form class="form-horizontal" v-on:submit.prevent="saveChanges">
      <div class="form-group">
        <label for="title">Signature:</label>
        <input
          type="text"
          name="title"
          v-model="testimonial.signature"
          placeholder="Signature for Testimonial"
          class="form-control"
        />
      </div>

      <div class="form-group">
        <label for="body">Body</label>
        <textarea
          class="form-control"
          rows="4"
          v-model="testimonial.body"
        ></textarea>
      </div>

      <div class="form-group">
        <label for="body">Sort Date:</label>
        <datepicker
          placeholder="Release Date"
          v-model="testimonial.sort_date"
          name="sort_date"
          input-class="form-control"
        ></datepicker>
      </div>

      <div class="form-group signup-carousel-buttons">
        <button class="btn btn-primary">Save</button>
      </div>
    </form>
  </div>
</template>

<style></style>

<script>
import TestimonialService from "../../services/TestimonialService";
import Datepicker from "vuejs-datepicker";

export default {
  data: function () {
    return {
      testimonial: {
        signature: "",
        body: "",
        sort_date: "",
      },
    };
  },

  created: function () {
    if (!isNaN(this.$route.params.id)) {
      TestimonialService.findById(this.$route.params.id).then((response) => {
        let data = response.data;
        this.testimonial.signature = data.signature;
        this.testimonial.body = data.body;
        this.testimonial.sort_date = data.sort_date;
      });
    }
  },

  components: {
    datepicker: Datepicker,
  },

  methods: {
    saveChanges: function () {
      let testimonialRow = {
        signature: this.testimonial.signature || "",
        body: this.testimonial.body || "",
        sortdate: new Date(this.testimonial.sort_date || ""),
      };

      if (!isNaN(this.$route.params.id)) {
        TestimonialService.update(this.$route.params.id, testimonialRow).then(
          (response) => {
            alert("Testimonial Updated");
          }
        );
      } else if (this.$route.params.id == "add") {
        TestimonialService.create(testimonialRow).then((response) => {
          alert("Testimonial created.");
        });
      }
    },
  },
};
</script>
