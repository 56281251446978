<template>
  <div class="blogs">
    <div class="modal fade" id="deleteRecordModal" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              Confirm you would like to delete this blog!
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>Are you sure you would like to delete this blog?</p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              @click="saveDeleteRecord"
            >
              Yes
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              No
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row header">
      <div class="col-xs-6 col-md-6 header-group">
        <h2>Blogs</h2>
      </div>
      <div class="col-xs-6 col-md-6 search">
        <input
          class="form-control form-control-sm w-100"
          type="text"
          placeholder="Search"
          aria-label="Search"
          v-model="search"
        />
      </div>
    </div>
    <table class="table">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Title</th>
          <th scope="col">
            <router-link :to="{ path: '/blogs/add' }"
              ><button type="button" class="btn btn-info">
                Add
              </button></router-link
            >
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(blog, index) in filteredBlogs" v-bind:key="blog.id">
          <th scope="row">{{ blog.id }}</th>
          <td>{{ blog.title }}</td>
          <td>
            <a
              :href="'https://fitmotivation.com/blog/' + blog.slug"
              target="_blank"
              >/blog/{{ blog.slug }}</a
            >
          </td>
          <td>
            <router-link :to="{ path: 'blogs/' + blog.id }"
              ><button type="button" class="btn btn-info">
                Edit
              </button></router-link
            >
            <button
              type="button"
              class="btn btn-danger"
              @click="confirmDeleteRecord($event, blog.id, index)"
            >
              Delete
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<style></style>

<script>
import BlogService from "../../services/BlogService";

export default {
  data: function () {
    return {
      blogs: {
        posts: [],
      },
      deleteRecord: {
        index: 0,
        id: 0,
      },
      search: "",
    };
  },

  methods: {
    saveDeleteRecord: function () {
      BlogService.delete(this.deleteRecord.id).then((response) => {
        console.log(response);
        //$('#deleteRecordModal').modal('hide');
        $("[data-dismiss=modal]").trigger({ type: "click" });
        this.blogs.posts.splice(this.deleteRecord.index, 1);
      });
    },

    confirmDeleteRecord: function (event, id, index) {
      this.deleteRecord = {
        id: id,
        index: index,
      };

      $("#deleteRecordModal").modal({});
    },
  },

  created: function () {
    BlogService.all().then((response) => {
      this.blogs.posts = response.data;
    });
  },

  computed: {
    filteredBlogs() {
      return this.blogs.posts.filter((item) => {
        let searchPhrase = this.search.toLowerCase();

        return item.title.toLowerCase().indexOf(searchPhrase) > -1;
      });
    },
  },
};
</script>
