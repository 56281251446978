<template>
  <div class="pages">
    <div class="row header">
      <div class="col-xs-10 col-md-10 header-group">
        <h2>Pages</h2>
      </div>
    </div>
    <table class="table-striped table table-hover table-condensed">
      <tr>
        <th>Description</th>
        <th>Identifier</th>
        <th>Type</th>
        <th>&nbsp;</th>
      </tr>
      <tr v-for="page in pages" v-bind:key="page.id">
        <td>{{ page.description }}</td>
        <td>{{ page.identifier }}</td>
        <td>{{ page.type }}</td>
        <td style="width: 200px">
          <router-link :to="{ path: '/pages/' + page.id }"
            ><button class="btn btn-success btn-xs">Edit</button></router-link
          >
        </td>
      </tr>
    </table>
  </div>
</template>

<style></style>

<script>
import PageService from "../../services/PageService";

export default {
  data: function () {
    return {
      pages: [],
      deleteRecord: {
        index: 0,
        id: 0,
      },
    };
  },

  created: function () {
    PageService.all().then((response) => {
      this.pages = response.data;
    });
  },
};
</script>
