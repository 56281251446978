import Vue from "vue";
import AccountService from "./AccountService";

export default {
  create(post) {
    let apiMethodUrl = process.env.VUE_APP_ROOT_API + "/blogs";
    let blogPostPayload = {};
    let options = AccountService.getSessionHeaders();

    blogPostPayload.title = post.title;
    blogPostPayload.meta_description = post.meta_description;
    blogPostPayload.meta_title = post.meta_title;
    blogPostPayload.post = post.post;
    blogPostPayload.excerpt = post.excerpt;
    blogPostPayload.thumbnail = post.thumbnail;
    blogPostPayload.date_released = post.date_released;
    blogPostPayload.featured = post.featured;
    blogPostPayload.hero_photo = post.heroPhoto;
    blogPostPayload.slug = post.slug;

    return Vue.axios.post(apiMethodUrl, blogPostPayload, options);
  },

  update(id, post) {
    let apiMethodUrl = process.env.VUE_APP_ROOT_API + "/blogs/" + id;
    let blogPostPayload = {};
    let options = AccountService.getSessionHeaders();

    blogPostPayload.title = post.title;
    blogPostPayload.meta_description = post.meta_description;
    blogPostPayload.meta_title = post.meta_title;
    blogPostPayload.post = post.post;
    blogPostPayload.excerpt = post.excerpt;
    blogPostPayload.thumbnail = post.thumbnail;
    blogPostPayload.date_released = post.date_released;
    blogPostPayload.featured = post.featured;
    blogPostPayload.hero_photo = post.heroPhoto;
    blogPostPayload.slug = post.slug;

    return Vue.axios.patch(apiMethodUrl, blogPostPayload, options);
  },

  delete(id) {
    let apiMethodUrl = process.env.VUE_APP_ROOT_API + "/blogs/" + id;
    let options = AccountService.getSessionHeaders();

    return Vue.axios.delete(apiMethodUrl, options);
  },

  findById(id) {
    let apiMethodUrl = process.env.VUE_APP_ROOT_API + "/blogs/" + id;
    let options = AccountService.getSessionHeaders();

    return Vue.axios.get(apiMethodUrl, options);
  },

  all() {
    let apiMethodUrl = process.env.VUE_APP_ROOT_API + "/blogs";
    let options = AccountService.getSessionHeaders();
    return Vue.axios.get(apiMethodUrl, options);
  },
};
