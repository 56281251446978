<template>
  <nav class="navbar navbar-dark fixed-top bg-dark flex-md-nowrap p-0 shadow">
    <a class="navbar-brand col-sm-3 col-md-2 mr-0" href="#"
      >Fitmotivation.com</a
    >
    <!-- <input class="form-control form-control-dark w-100" type="text" placeholder="Search" aria-label="Search"> -->
    <ul class="navbar-nav px-3">
      <li class="nav-item text-nowrap">
        <a class="nav-link" @click="signOut">Sign out</a>
      </li>
    </ul>
  </nav>
</template>

<script>
import AccountService from "../services/AccountService";

export default {
  methods: {
    signOut: function () {
      AccountService.deleteSession();
      window.location.href = "/login";
    },
  },

  created: function () {
    AccountService.validateSession()
      .then((response) => {
        if (response.data.enabled === 0) {
          this.signOut();
        }
      })
      .catch((err) => {
        this.signOut();
      });
  },
};
</script>
<style></style>
