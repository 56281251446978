<template>
  <div class="row justify-content-center">
    <div class="col-lg-6 col-md-6 col-sm-12">
      <div class="card">
        <h5 class="card-header">Sign In to Fitmotivation Admin</h5>
        <div class="card-body">
          <form v-on:submit.prevent="login">
            <div class="alert alert-danger" v-if="valid == false" role="alert">
              Please check your login credentials and try again!
            </div>
            <div class="form-group row">
              <div class="col-12">
                <label for="email" class="form-label">Email:</label>
                <input
                  class="form-control form-control-lg"
                  type="text"
                  placeholder="email"
                  id="email"
                  v-model="email"
                />
              </div>
            </div>
            <div class="form-group row">
              <div class="col-12">
                <label for="password" class="form-label">Password:</label>
                <input
                  class="form-control form-control-lg"
                  type="password"
                  placeholder="password"
                  id="password"
                  v-model="password"
                />
              </div>
            </div>
            <div class="form-group row">
              <div class="col-12">
                <button class="btn btn-primary float-right">Login</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AccountService from "../services/AccountService";

export default {
  methods: {
    login() {
      let userDataObj = {
        email: this.email,
        password: this.password,
      };

      AccountService.login(userDataObj)
        .then((response) => {
          let data = response.data;

          console.log(data);

          if (
            data.customer_profile.enabled === 1 &&
            data.customer_profile.permission === "admin"
          ) {
            this.$cookies.set("sessionToken", response.data.token, "3d");
            this.$cookies.set("userName", userDataObj.email, "3d");

            this.$storage.set("fmsession", response.data, { ttl: 60 * 1000 });

            window.location.href = "/";
          } else {
            alert("Account is disabled.");
          }
        })
        .catch((err) => {
          this.valid = false;
          this.$cookies.remove("sessionToken");
          this.$cookies.remove("userName");
          this.$storage.remove("fmsession");
          console.log(err);
        });
    },
  },

  data: function () {
    // use this method to get data.
    return {
      email: "",
      password: "",
      valid: true,
    };
  },
};
</script>

<style></style>
