import Vue from "vue";
import AccountService from "./AccountService";

export default {
  create(tip) {
    let apiMethodUrl = process.env.VUE_APP_ROOT_API + "/tips";
    let tipPayload = {};
    let options = AccountService.getSessionHeaders();

    tipPayload.title = tip.title;
    tipPayload.meta_title = tip.meta_title;
    tipPayload.meta_description = tip.meta_description;
    tipPayload.thumbnail = tip.thumbnail;
    tipPayload.video_url = tip.video_url;
    tipPayload.body = tip.body;
    tipPayload.featured = tip.featured;
    tipPayload.release_date = tip.release_date;

    return Vue.axios.post(apiMethodUrl, tipPayload, options);
  },

  update(id, tip) {
    let apiMethodUrl = process.env.VUE_APP_ROOT_API + "/tips/" + id;
    let tipPayload = {};
    let options = AccountService.getSessionHeaders();
    
    tipPayload.title = tip.title;
    tipPayload.meta_title = tip.meta_title;
    tipPayload.meta_description = tip.meta_description;
    tipPayload.thumbnail = tip.thumbnail;
    tipPayload.video_url = tip.video_url;
    tipPayload.body = tip.body;
    tipPayload.featured = tip.featured;
    tipPayload.release_date = tip.release_date;

    return Vue.axios.patch(apiMethodUrl, tipPayload, options);
  },

  delete(id) {
    let apiMethodUrl = process.env.VUE_APP_ROOT_API + "/tips/" + id;
    let options = AccountService.getSessionHeaders();

    return Vue.axios.delete(apiMethodUrl, options);
  },

  all() {
    let apiMethodUrl = process.env.VUE_APP_ROOT_API + "/tips";
    return Vue.axios.get(apiMethodUrl);
  },

  findById(id) {
    let apiMethodUrl = process.env.VUE_APP_ROOT_API + "/tips/" + id;
    return Vue.axios.get(apiMethodUrl);
  },
};
