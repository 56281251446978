import Vue from "vue";
import AccountService from "./AccountService";

export default {
  create(testimonial) {
    let apiMethodUrl = process.env.VUE_APP_ROOT_API + "/testimonials";
    let testimonialPayload = {};
    let options = AccountService.getSessionHeaders();
    testimonialPayload.body = testimonial.body;
    testimonialPayload.signature = testimonial.signature;
    testimonialPayload.sortdate = testimonial.sortdate;

    return Vue.axios.post(apiMethodUrl, testimonialPayload, options);
  },

  update(id, testimonial) {
    let apiMethodUrl = process.env.VUE_APP_ROOT_API + "/testimonials/" + id;
    let testimonialPayload = {};
    let options = AccountService.getSessionHeaders();
    testimonialPayload.body = testimonial.body;
    testimonialPayload.signature = testimonial.signature;
    testimonialPayload.sortdate = testimonial.sortdate;

    return Vue.axios.patch(apiMethodUrl, testimonialPayload, options);
  },

  delete(id) {
    let apiMethodUrl = process.env.VUE_APP_ROOT_API + "/testimonials/" + id;
    let options = AccountService.getSessionHeaders();

    return Vue.axios.delete(apiMethodUrl, options);
  },

  all() {
    let apiMethodUrl = process.env.VUE_APP_ROOT_API + "/testimonials";
    return Vue.axios.get(apiMethodUrl);
  },

  findById(id) {
    let apiMethodUrl = process.env.VUE_APP_ROOT_API + "/testimonials/" + id;
    return Vue.axios.get(apiMethodUrl);
  },
};
