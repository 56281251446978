import Vue from "vue";
import AccountService from "./AccountService";

export default {
  changePassword(passwordChangeEmail, password) {
    let apiMethodUrl = process.env.VUE_APP_ROOT_API + "/user/password/change";
    let options = AccountService.getSessionHeaders();
    let userPayload = {};
    userPayload.passwordChangeEmail = passwordChangeEmail;
    userPayload.password = password;

    return Vue.axios.post(apiMethodUrl, userPayload, options);
  },

  update(id, userRow) {
    let apiMethodUrl = process.env.VUE_APP_ROOT_API + "/customers/" + id;
    let options = AccountService.getSessionHeaders();

    return Vue.axios.patch(apiMethodUrl, userRow, options);
  },

  delete(id) {
    let apiMethodUrl = process.env.VUE_APP_ROOT_API + "/stripe/cancel/" + id;
    let options = AccountService.getSessionHeaders();

    return Vue.axios.delete(apiMethodUrl, options);
  },

  all() {
    let apiMethodUrl = process.env.VUE_APP_ROOT_API + "/customers";
    let options = AccountService.getSessionHeaders();

    return Vue.axios.get(apiMethodUrl, options);
  },

  filteredCustomers(filter, column = null, sort = null) {
    let apiMethodUrl = '';
    let options = AccountService.getSessionHeaders();

    if (sort !== null) {
      apiMethodUrl = process.env.VUE_APP_ROOT_API + "/customers?filter=" + filter + "&sort=" + sort + "&column=" + column;
    } else {
      apiMethodUrl = process.env.VUE_APP_ROOT_API + "/customers?filter=" + filter;
    }

    return Vue.axios.get(apiMethodUrl, options);
  },

  allFiltered(category) {
    let apiMethodUrl = process.env.VUE_APP_ROOT_API + "/user/all";
    let options = AccountService.getSessionHeaders();
    let userPayload = {};
    userPayload.filter = category;

    return Vue.axios.post(apiMethodUrl, userPayload, options);
  },

  findById(id) {
    let apiMethodUrl = process.env.VUE_APP_ROOT_API + "/customers/" + id;
    let options = AccountService.getSessionHeaders();

    return Vue.axios.get(apiMethodUrl, options);
  },

  allDelinquent() {
    let apiMethodUrl = process.env.VUE_APP_ROOT_API + "/user/delinquent";
    let options = AccountService.getSessionHeaders();
    let userPayload = {};

    return Vue.axios.post(apiMethodUrl, userPayload, options);
  },

  getFullStats() {
    let apiMethodUrl = process.env.VUE_APP_ROOT_API + "/user/stats/full";

    return Vue.axios.get(apiMethodUrl);
  },

  getPlanCounts() {
    let apiMethodUrl = process.env.VUE_APP_ROOT_API + "/customers/counts";
    let options = AccountService.getSessionHeaders();

    return Vue.axios.get(apiMethodUrl, options);
  }
};
