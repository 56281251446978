import Vue from "vue";
import AccountService from "./AccountService";

export default {
  create(bio) {
    let apiMethodUrl = process.env.VUE_APP_ROOT_API + "/instructors";
    let bioPayload = {};
    let options = AccountService.getSessionHeaders();
    bioPayload.fullname = bio.fullname;
    bioPayload.meta_description = bio.meta_description;
    bioPayload.meta_title = bio.meta_title;
    bioPayload.emailAddress = bio.email;
    bioPayload.body = bio.body;
    bioPayload.photo01 = bio.photo01;
    bioPayload.photo02 = bio.photo02;
    bioPayload.sortdate = bio.sortdate;
    bioPayload.featured = bio.featured;
    bioPayload.hero_photo = bio.hero_photo;

    return Vue.axios.post(apiMethodUrl, bioPayload, options);
  },

  update(id, bio) {
    let apiMethodUrl = process.env.VUE_APP_ROOT_API + "/instructors/" + id;
    let options = AccountService.getSessionHeaders();
    let bioPayload = {};
    bioPayload.fullname = bio.fullname;
    bioPayload.meta_description = bio.meta_description;
    bioPayload.meta_title = bio.meta_title;
    bioPayload.emailAddress = bio.email;
    bioPayload.body = bio.body;
    bioPayload.photo01 = bio.photo01;
    bioPayload.photo02 = bio.photo02;
    bioPayload.sortdate = bio.sortdate;
    bioPayload.featured = bio.featured;
    bioPayload.hero_photo = bio.hero_photo;

    return Vue.axios.patch(apiMethodUrl, bioPayload, options);
  },

  delete(id) {
    let apiMethodUrl = process.env.VUE_APP_ROOT_API + "/instructors/" + id;
    let options = AccountService.getSessionHeaders();

    return Vue.axios.delete(apiMethodUrl, options);
  },

  findById(id) {
    let apiMethodUrl = process.env.VUE_APP_ROOT_API + "/instructors/" + id;
    let options = AccountService.getSessionHeaders();
    return Vue.axios.get(apiMethodUrl, options);
  },

  all() {
    let options = AccountService.getSessionHeaders();
    let apiMethodUrl = process.env.VUE_APP_ROOT_API + "/instructors";
    return Vue.axios.get(apiMethodUrl, options);
  },
};
