<template>
  <div class="video-categorization">
    <div class="modal fade" id="deleteRecordModal" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              Confirm you would like to delete this categorization!
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>Are you sure you would like to delete this categorization?</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="saveDeleteRecord">
              Yes
            </button>
            <button type="button" class="btn btn-secondary" data-dismiss="modal">
              No
            </button>
          </div>
        </div>
      </div>
    </div>
    <table class="table-striped table table-hover table-condensed">
      <thead>
        <tr>
          <th>Video</th>
          <th>Categories</th>
          <th>
            <router-link :to="{ path: '/categorize-videos/add' }"><button class="btn btn-primary pull-right">
                Add
              </button></router-link>
          </th>
        </tr>
      </thead>
      <tbody v-if="isLoaded">
        <!-- ng-repeat="(category,vids) in videos | groupBy: 'category'" <tr>
				<th colspan="2">{{ category }}</th>
			</tr> -->
        <tr v-for="(categorize, index) in categorizations" v-bind:key="categorize.id">
          <td>{{ categorize.title }}</td>
          <td>
            <p v-for="category in categorize.categories" v-bind:key="category.title">
              {{ category.category }}
              <button class="btn btn-danger btn-xs" @click="
                confirmDeleteRecord(
                  $event,
                  category.video_id,
                  category.category_id,
                  index
                )
              ">
                Delete
              </button>
            </p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<style>
</style>

<script>
import _ from "lodash";
import VideoCategorizationService from "../../services/VideoCategorizationService";

export default {
  data: function () {
    return {
      isLoaded: false,
      categorizationsData: [],
      deleteRecord: {
        index: 0,
        video_id: 0,
        category_id: 0,
      },
    };
  },

  created: function () {
    VideoCategorizationService.all().then((response) => {
      this.categorizationsData = response.data;
      this.isLoaded = true;
    });
  },

  computed: {
    categorizations() {
      return _.chain(this.categorizationsData, "title")
        .groupBy("title")
        .toPairs()
        .map(function (currentData) {
          return _.zipObject(["title", "categories"], currentData);
        })
        .value();
    },
  },

  methods: {
    confirmDeleteRecord: function (event, videoId, categoryId, index) {
      this.deleteRecord = {
        video_id: videoId,
        category_id: categoryId,
        index: index,
      };
      $('#deleteRecordModal').modal({});
    },


    saveDeleteRecord: function () {
      VideoCategorizationService.delete(
        this.deleteRecord.video_id,
        this.deleteRecord.category_id
      ).then((response) => {
        $("[data-dismiss=modal]").trigger({ type: "click" });
        this.categorizations.splice(this.deleteRecord.index, 1);
      });
    },
  },
};

</script>
