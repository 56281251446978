<template>
  <div class="videoForm" v-on:submit.prevent="saveChanges">
    <form class="form-horizontal">
      <div class="row">
        <label for="trial_workout">Trial:</label>
        <div class="form-check">
          <input
            type="radio"
            class="form-check-input"
            name="trial_workout"
            id="trial_workout_yes"
            value="1"
            v-model="video.trial"
          />
          <label for="featured_media_type_video" class="form-check-label"
            >Yes</label
          >
        </div>
        <div class="form-check" style="margin-left: 15px">
          <input
            type="radio"
            class="form-check-input"
            name="trial_workout"
            id="trial_workout_no"
            value="0"
            v-model="video.trial"
          />
          <label for="featured_media_type_image" class="form-check-label"
            >No</label
          >
        </div>
      </div>
      <div class="form-group">
        <label for="title">Title:</label>
        <input type="text" name="title" v-model="video.title" placeholder="Title" class="form-control" />
      </div>
      <div class="form-group">
        <label for="metaTitle">Meta Title (SEO):</label>
        <input type="text" v-model="video.metaTitle" class="form-control" name="metaTitle" id="metaTitle"
          placeholder="" />
        <div class="invalid-feedback">
          Please provide a valid meta title.
        </div>
      </div>

      <div class="form-group">
        <label for="metaDescription">Meta Description (SEO):</label>
        <textarea v-model="video.metaDescription" class="form-control" name="metaDescription" id="metaDescription"
          placeholder=""></textarea>
        <div class="invalid-feedback">
          Please provide a valid meta description.
        </div>
      </div>
      <div class="form-group">
        <label for="keywords">Keywords (SEO):</label>
        <input type="text" v-model="video.keywords" class="form-control" name="keywords" id="keywords" placeholder="" />
        <div class="invalid-feedback">
          Please provide a valid keywords list.
        </div>
      </div>
      <div class="form-group">
        <div class="col-xs-5">
          <div class="form-group">
            <label for="VideoURL">Video URL:</label>
            <input type="text" name="title" v-model="video.videoUrl" v-on:blur="videoUrlChanged()"
              placeholder="Full video url" class="input-inline form-control" />
          </div>
          <div class="form-group">
            <label for="PreviewURL">Preview URL:</label>
            <input type="text" name="title" v-model="video.previewUrl" placeholder="Preview video url"
              class="input-inline form-control" />
          </div>
        </div>
        <div class="col-xs-4 col-md-push-1">
          <div class="form-group">
            <label for="image">Thumbnail:</label>
            <input type="text" placeholder="Thumbnail URL" name="thumbnail" v-model="video.thumbnail"
              class="input-inline form-control" />
            <img v-if="video.thumbnail && video.thumbnail.length > 0" :src="video.thumbnail" alt="Thumbnail Link" />
            <div v-show="video.videoUrl && video.videoUrl.length> 0">
              <button class="btn btn-primary" @click="uploadThumbnails">Upload Thumbnails</button>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label for="body">Body:</label>
        <editor api-key="h6ymu878nq2dmit84wddqdm84to1x90pryg1iec6s96rph86" name="body" id="body" v-model="video.body" class="wysiwyg-editor" :init="{
          plugins:
            'wordcount link image fullscreen lists textcolor colorpicker',
        }"></editor>
      </div>
      <div class="form-group">
        <label for="body">Release Date:</label>
        <datepicker placeholder="Release Date" v-model="video.release_date" name="release_date"
          input-class="form-control"></datepicker>
      </div>

      <fieldset>
        <legend>Handout Link</legend>
        <div class="form-group">
          <label for="body">Handout Title:</label>
          <input type="text" placeholder="Handout title" name="handout_title" v-model="PostLinkHandoutTitle"
            class="input-inline form-control" />
        </div>
        <div class="form-group">
          <label for="body">Handout URL:</label>
          <input type="text" placeholder="Handout url" name="handout_url" v-model="PostLinkHandoutUrl"
            class="input-inline form-control" />
        </div>
      </fieldset>
      <fieldset>
        <legend>Blog Link</legend>
        <div class="form-group">
          <label for="body">Blog Title:</label>
          <input type="text" placeholder="Blog title" name="blog_title" v-model="PostLinkBlogTitle"
            class="input-inline form-control" />
        </div>
        <div class="form-group">
          <label for="body">Blog URL:</label>
          <input type="text" placeholder="Blog url" name="blog_url" v-model="PostLinkBlogUrl"
            class="input-inline form-control" />
        </div>
      </fieldset>
      <fieldset>
        <legend>Audio Purchase Link</legend>
        <div class="form-group">
          <label for="audio_title">Audio Download Title:</label>
          <input type="text" placeholder="Audio download title" name="audio_title" v-model="PostLinkAudioTitle"
            class="input-inline form-control" />
        </div>
        <div class="form-group">
          <label for="audio_url">Audio Download URL:</label>
          <input type="text" placeholder="Audio download url" name="audio_url" v-model="PostLinkAudioUrl"
            class="input-inline form-control" />
        </div>
      </fieldset>
      <fieldset>
        <legend>CEC Purchase Link</legend>
        <div class="form-group">
          <label for="cec_title">Quiz Title:</label>
          <input type="text" placeholder="Quiz title" name="cec_title" v-model="PostLinkCECTitle"
            class="input-inline form-control" />
        </div>
        <div class="form-group">
          <label for="cec_url">Quiz URL:</label>
          <input type="text" placeholder="Quiz url" name="cec_url" v-model="PostLinkCECUrl"
            class="input-inline form-control" />
        </div>
      </fieldset>

      <div class="form-group">
        <label for="body">Membership Level:</label>
        <select class="form-control" v-model="PostLevel">
          <option v-for="plan in planTypes" v-bind:key="plan.value" :value="plan.value">
            {{ plan.label }}
          </option>
        </select>
      </div>

      <div class="form-group">
        <label for="body">Premium Download Available:</label>
        <select class="form-control" v-model="PremiumDownloadAvailable">
          <option v-for="opt in premiumDownloadOptions" v-bind:key="opt.value" :value="opt.value">
            {{ opt.label }}
          </option>
        </select>
      </div>

      <div class="form-group signup-carousel-buttons">
        <button class="btn btn-primary">Save</button>
      </div>
    </form>
  </div>
</template>

<style>

</style>

<script>
import VideoService from "../../services/VideoService";
import ThumbnailService from "../../services/ThumbnailService";

import Datepicker from "vuejs-datepicker";
import Editor from "@tinymce/tinymce-vue";

export default {
  data: function () {
    return {     
      video: {
        handoutLink: null,
        thumbnail: null,
        blogLink: null,
        cecPurchaseLink: null,
        audioPurchaseLink: null,
      },

      PostLinkHandoutTitle: null,
      PostLinkHandoutUrl: null,
      PostLinkBlogTitle: null,
      PostLinkBlogUrl: null,
      PostLinkAudioTitle: null,
      PostLinkAudioUrl: null,
      PostLinkCECTitle: null,
      PostLinkCECUrl: null,
      PostLevel: null,
      PremiumDownloadAvailable: null,
      video_thumbnail: null,

      planTypes: [
        { value: "1", label: "Basic and Premium" },
        { value: "2", label: "Premium Only" },
      ],

      premiumDownloadOptions: [
        { value: "0", label: "No" },
        { value: "1", label: "Yes" },
      ],
    };
  },

  created() {
    if (!isNaN(this.$route.params.id)) {
      VideoService.findById(this.$route.params.id).then((response) => {
        let data = response.data;

        this.video = {
          title: data.title,
          videoUrl: data.video_url,
          previewUrl: data.preview_url,
          thumbnail: data.thumbnail,
          body: data.body,
          release_date: data.release_date,
          metaTitle: data.meta_title,
          metaDescription: data.meta_description,
          keywords: data.keywords,
          trial: data.trial
        };

        if (data.blog_link !== null) {
          this.PostLinkBlogTitle = data.blog_link.title;
          this.PostLinkBlogUrl = data.blog_link.href;
        }

        if (data.handout_link !== null) {
          this.PostLinkHandoutTitle = data.handout_link.title;
          this.PostLinkHandoutUrl = data.handout_link.href;
        }

        if (data.audio_purchase_link !== null) {
          this.PostLinkAudioTitle = data.audio_purchase_link.title;
          this.PostLinkAudioUrl = data.audio_purchase_link.href;
        }

        if (data.cec_purchase_link !== null) {
          this.PostLinkCECTitle = data.cec_purchase_link.title;
          this.PostLinkCECUrl = data.cec_purchase_link.href;
        }

        if (data.level === 1) {
          this.PostLevel = this.planTypes[0].value;
        } else if (data.level === 2) {
          this.PostLevel = this.planTypes[1].value;
        }

        if (data.premium_download_available === 0) {
          this.PremiumDownloadAvailable = this.premiumDownloadOptions[0].value;
        } else if (data.premium_download_available === 1) {
          this.PremiumDownloadAvailable = this.premiumDownloadOptions[1].value;
        }
      });
    } else if (this.$route.params.id !== "add") {
      alert("Invalid parameter.");
    }
  },

  components: {
    editor: Editor,
    datepicker: Datepicker,
  },

  methods: {
    saveChanges: function () {
      let audioPurchaseLink = null;
      let blogLink = null;
      let handoutLink = null;
      let cecPurchaseLink = null;

      if (this.PostLinkBlogTitle && this.PostLinkBlogUrl) {
        blogLink = {
          href: this.PostLinkBlogUrl,
          title: this.PostLinkBlogTitle,
        };
      }

      if (this.PostLinkHandoutTitle && this.PostLinkHandoutUrl) {
        handoutLink = {
          href: this.PostLinkHandoutUrl,
          title: this.PostLinkHandoutTitle,
        };
      }

      if (this.PostLinkAudioTitle && this.PostLinkAudioUrl) {
        audioPurchaseLink = {
          href: this.PostLinkAudioUrl,
          title: this.PostLinkAudioTitle,
        };
      }

      if (this.PostLinkCECTitle && this.PostLinkCECUrl) {
        cecPurchaseLink = {
          href: this.PostLinkCECUrl,
          title: this.PostLinkCECTitle,
        };
      }

      let post = {
        trial: this.video.trial || 0,
        title: this.video.title || "",
        body: this.video.body,
        videoUrl: this.video.videoUrl || "",
        previewUrl: this.video.previewUrl || "",
        thumbnail: this.video.thumbnail || "",
        dateReleased: new Date(this.video.release_date || ""),
        blogLink: blogLink,
        cecPurchaseLink: cecPurchaseLink,
        audioPurchaseLink: audioPurchaseLink,
        handoutLink: handoutLink,
        level: this.PostLevel || "",
        metaTitle: this.video.metaTitle || "",
        metaDescription: this.video.metaDescription || "",
        keywords: this.video.keywords || "",
        premium_download_available: this.PremiumDownloadAvailable || 0,
      };

      if (!isNaN(this.$route.params.id)) {
        VideoService.update(this.$route.params.id, post).then((response) => {
          alert("Post Updated");
        });
      } else if (this.$route.params.id == "add") {
        VideoService.create(post).then((response) => {
          alert("Post Created");
        });
      } else {
        alert("invalid");
      }
    },

    uploadThumbnails: function() {
      ThumbnailService.uploadThumbnailsToS3ForVimeoEmbedLink(this.video.videoUrl).then(response => {
        alert("Successfully uploaded thumbnails");
      }).catch(err => {
        alert("An error occurred");
      });
      
    },

    videoUrlChanged: function () {
      if (this.video.videoUrl.length > 0) {
        let vimeoVideoId = this.video.videoUrl.split("/").pop();

        if (vimeoVideoId) {
          this.axios
            .get(
              "https://vimeo.com/api/oembed.json?url=http%3A//vimeo.com/" +
              vimeoVideoId
            )
            .then((response) => {
              let thumbnailLink = response.data.thumbnail_url.replace('.webp', '').split('_');

              let thumbnailLinkLowRes = thumbnailLink[0] + '_300.jpg';
              
              this.video.thumbnail = 'https://d18cfrkvlsavt2.cloudfront.net/thumbnails/' + thumbnailLinkLowRes.replace('.jpg', '').split('/').reverse()[0];
            });
        }
      }
    },
  },
};
</script>
