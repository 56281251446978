<template>
  <div class="quizForm">
    <form class="form-horizontal" v-on:submit.prevent="saveChanges">
      <div class="form-group">
        <label for="title">Title:</label>
        <input
          type="text"
          name="title"
          v-model="quiz.title"
          placeholder="Title of quiz"
          class="form-control"
        />
      </div>
      <div class="form-group">
        <label for="title">Content Code (Gauge):</label>
        <input
          type="text"
          name="content_code"
          v-model="quiz.content_code"
          placeholder="Content code of quiz"
          class="form-control"
        />
      </div>
      <div class="form-group">
        <label for="metaTitle">Meta Title (SEO):</label>
        <input type="text" v-model="quiz.metaTitle" class="form-control" name="metaTitle" id="metaTitle"
          placeholder="" />
        <div class="invalid-feedback">
          Please provide a valid meta title.
        </div>
      </div>

      <div class="form-group">
        <label for="metaDescription">Meta Description (SEO):</label>
        <textarea v-model="quiz.metaDescription" class="form-control" name="metaDescription" id="metaDescription"
          placeholder=""></textarea>
        <div class="invalid-feedback">
          Please provide a valid meta description.
        </div>
      </div>
      <div class="form-group">
        <label for="course_number">Course Number:</label>
        <input
          type="text"
          name="course_number"
          v-model="quiz.course_number"
          placeholder="Course number of quiz"
          class="form-control"
        />
      </div>
      <div class="form-group">
        <label for="price">Price in Cents:</label>
        <input
          type="text"
          name="price"
          v-model="quiz.price"
          placeholder="Price in cents"
          class="form-control"
        />
      </div>

      <div class="form-group">
        <label for="price">Number of Questions:</label>
        <input
          type="text"
          name="price"
          v-model="quiz.numberOfQuestions"
          placeholder="Number of questions"
          class="form-control"
        />
      </div>

      <div class="form-group">
        <label for="price">Approved CECs:</label>
        <input
          type="text"
          name="price"
          v-model="quiz.approved_cecs"
          placeholder="Approved CECs"
          class="form-control"
        />
      </div>
      <div class="form-group">
        <label for="handout">Handout:</label>
        <input
          type="text"
          name="handout"
          v-model="quiz.handout"
          placeholder="Link to handout"
          class="form-control"
        />
      </div>
      <div class="form-group">
        <label for="body">Release Date:</label>
        <datepicker
          placeholder="Date to release"
          v-model="quiz.release_date"
          name="release_date"
          input-class="form-control"
        ></datepicker>
      </div>
      <div class="form-group">
        <label for="body">Video:</label>
        <select class="form-control" v-model="quiz.video">
          <option
            v-for="video in videos"
            v-bind:key="video.id"
            :value="video.id"
          >
            {{ video.title }}
          </option>
        </select>
      </div>
      <div class="form-group">
        <label for="body">Description:</label>
        <textarea
          class="form-control"
          rows="4"
          v-model="quiz.description"
        ></textarea>
      </div>
      <div class="form-group">
        <label for="body">Instructions:</label>
        <editor
          api-key="h6ymu878nq2dmit84wddqdm84to1x90pryg1iec6s96rph86"
          name="body"
          id="body"
          v-model="quiz.body"
          class="wysiwyg-editor"
          :init="{
            plugins:
              'wordcount link image fullscreen lists textcolor colorpicker',
          }"
        ></editor>
      </div>
      <div class="form-group signup-carousel-buttons">
        <button class="btn btn-primary">Save</button>
      </div>
    </form>
  </div>
</template>

<style></style>

<script>
import QuizService from "../../services/QuizService";
import VideoService from "../../services/VideoService";
import Editor from "@tinymce/tinymce-vue";
import Datepicker from "vuejs-datepicker";

export default {
  data: function () {
    return {
      quiz: {
        title: "",
        handout: "",
        video: "",
        course_number: "",
        approved_cecs: "",
        instructions: "",
        description: "",
        answers: [],
        questions: [],
        price: 0
      },

      videos: [],
    };
  },

  created () {
    VideoService.all().then((response) => {
      this.videos = response.data;
    });

    if (!isNaN(this.$route.params.id)) {
      QuizService.findById(this.$route.params.id).then((response) => {
        let data = response.data;
        this.quiz.title = data.title;
        this.quiz.content_code = data.content_code;
        this.quiz.metaTitle = data.meta_title;
        this.quiz.metaDescription = data.meta_description;
        this.quiz.handout = data.handout;
        this.quiz.body = data.instructions;
        this.quiz.description = data.description;
        this.quiz.course_number = data.course_number;
        this.quiz.release_date = data.release_date;
        this.quiz.price = data.price;
        this.quiz.numberOfQuestions = data.number_of_questions;
        this.quiz.approved_cecs = data.approved_cecs;

        if (data.video) {
          this.quiz.video = data.video;
        }
      });
    }
  },

  components: {
    editor: Editor,
    datepicker: Datepicker,
  },

  methods: {
    saveChanges () {
      let quizRow = {
        title: this.quiz.title || "",
        content_code: this.quiz.content_code || "",
        meta_title: this.quiz.metaTitle || "",
        meta_description: this.quiz.metaDescription || "",
        handout: this.quiz.handout || "",
        video: { id: this.quiz.video } || null,
        course_number: this.quiz.course_number || "",
        instructions: this.quiz.body,
        description: this.quiz.description,
        release_date: new Date(this.quiz.release_date || ""),
        price: this.quiz.price,
        number_of_questions: this.quiz.numberOfQuestions,
        approved_cecs: this.quiz.approved_cecs
      };

      if (!isNaN(this.$route.params.id)) {
        QuizService.update(this.$route.params.id, quizRow).then((response) => {
          alert("Quiz Updated");
        });
      } else if (this.$route.params.id == "add") {
        QuizService.create(quizRow).then((response) => {
          alert("Quiz created");
        });
      }
    },
  },
};
</script>
