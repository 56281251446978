<template>
  <div class="bioForm">
    <form class="form-horizontal" v-on:submit.prevent="saveChanges">
      <div class="form-group">
        <label for="featured">Featured:</label>
        <select class="form-control" name="video" v-model="bio.featured">
          <option value="0">No</option>
          <option value="1">
            Yes
          </option>
        </select>
      </div>
      <div class="form-group">
        <label for="firstname">Fullname:</label>
        <input type="text" name="firstname" v-model="bio.fullname" placeholder="Fullname" class="form-control" />
      </div>

      <div class="form-group">
        <label for="firstname">Email:</label>
        <input type="text" name="email" v-model="bio.email" placeholder="Email" class="form-control" />
      </div>

      <div class="form-group">
        <label for="body">Body:</label>
        <editor api-key="h6ymu878nq2dmit84wddqdm84to1x90pryg1iec6s96rph86" name="body" id="body" v-model="bio.body" class="wysiwyg-editor" :init="{
          plugins:
            'wordcount link image fullscreen lists textcolor colorpicker',
        }"></editor>
      </div>

      <div class="form-group">
        <label>Mugshot:</label>
        <input type="text" placeholder="Bio Photo 01" class="form-control" v-model="bio.photo01" />
      </div>

      <div class="form-group">
        <label>Action Shot:</label>
        <input type="text" placeholder="Bio Photo 02" class="form-control" v-model="bio.photo02" />
      </div>

      <div class="form-group">
        <label for="body">Sort Date:</label>
        <datepicker placeholder="Sort Date" v-model="bio.sort_date" name="sort_date" input-class="form-control">
        </datepicker>
      </div>

      <div class="form-group">
        <label for="hero_photo">Hero Photo:</label>
        <input type="text" placeholder="Bio Photo 02" class="form-control" v-model="bio.hero_photo" />
      </div>

      <div class="form-group signup-carousel-buttons">
        <button class="btn btn-primary">Save</button>
      </div>
    </form>
  </div>
</template>

<style>
</style>

<script>
import BioService from "../../services/BioService";
import Datepicker from "vuejs-datepicker";
import Editor from "@tinymce/tinymce-vue";

export default {
  data: function () {
    return {
      bio: {
        fullname: "",
        email: "",
        body: "",
        photo01: "",
        photo02: "",
        sort_date: "",
        hero_photo: ""
      },
    };
  },

  created: function () {
    if (!isNaN(this.$route.params.id)) {
      BioService.findById(this.$route.params.id).then((response) => {
        let data = response.data;
        this.bio.fullname = data.fullname;
        this.bio.email = data.email;
        this.bio.body = data.body;
        this.bio.photo01 = data.photo01;
        this.bio.photo02 = data.photo02;
        this.bio.sort_date = data.sort_date;
        this.bio.hero_photo = data.hero_photo;
        this.bio.featured = data.featured;
      });
    }
  },

  components: {
    datepicker: Datepicker,
    editor: Editor,
  },

  methods: {
    saveChanges: function () {
      let bioRow = {
        fullname: this.bio.fullname || "",
        body: this.bio.body,
        email: this.bio.email || "",
        photo01: this.bio.photo01 || "",
        photo02: this.bio.photo02 || "",
        sortdate: new Date(this.bio.sort_date || ""),
        hero_photo: this.bio.hero_photo || "",
        featured: this.bio.featured || 0
      };

      if (!isNaN(this.$route.params.id)) {
        BioService.update(this.$route.params.id, bioRow).then((response) => {
          alert("Bio Updated");
        });
      } else if (this.$route.params.id == "add") {
        BioService.create(bioRow).then((response) => {
          alert("Bio created.");
        });
      }
    },
  },
};
</script>
