<template>
  <div class="video-categorization-form">
    <div
      class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom"
    >
      <h1 class="h2">Assign Related Videos</h1>
    </div>
    <form class="form-horizontal" v-on:submit.prevent="saveChanges">
      <div class="form-group">
        <label for="body">Video:</label>
        <select class="form-control" v-model="assignment.video">
          <option
            v-for="video in videos"
            v-bind:key="video.id"
            :value="video.id"
          >
            {{ video.title }}
          </option>
        </select>
      </div>
      <div class="form-group">
        <label for="body">Related Video:</label>
        <select class="form-control" v-model="assignment.related_video">
          <option
            v-for="video in videos"
            v-bind:key="video.id"
            :value="video.id"
          >
            {{ video.title }}
          </option>
        </select>
      </div>
      <div class="form-group signup-carousel-buttons">
        <button class="btn btn-primary">Save</button>
      </div>
    </form>
  </div>
</template>

<style></style>

<script>
import VideoRelatedService from "../../services/VideoRelatedService";
import VideoService from "../../services/VideoService";

export default {
  data: function () {
    return {
      videos: [],
      assignment: {
        video: 0,
        related_video: 0,
      },
    };
  },

  created: function () {
    VideoService.all().then((response) => {
      this.videos = response.data;
    });
  },

  methods: {
    saveChanges: function () {
      if (this.assignment.video == 0 || this.assignment.related_video == 0) {
        alert("Please select both a video and related video.");
        return;
      }

      let videoRelatedRow = {
        videoId: this.assignment.video,
        relatedVideoId: this.assignment.related_video || "",
      };

      VideoRelatedService.create(videoRelatedRow).then((response) => {
        alert("Video categorized.");
      });
    },
  },
};
</script>
