<template>
  <form class="form-horizontal" v-on:submit.prevent="saveChanges">
    <div class="form-group">
      <label for="title">Title:</label>
      <input type="text" name="title" v-model="blog.title" placeholder="Title" class="form-control" />
    </div>

    <div class="form-group">
      <label for="title">Slug:</label>
      <input type="text" v-model="blog.slug" :class="{ 'form-control': true, 'is-invalid': errors.has('slug') }"
        name="slug" id="slug" placeholder="Slug" v-validate="'required'" />
      <div class="invalid-feedback">
        Please provide a valid slug.
      </div>
    </div>

    <div class="form-group">
      <label for="metaTitle">Meta Title (SEO):</label>
      <input type="text" v-model="blog.meta_title" class="form-control" name="meta_title" id="meta_title"
        placeholder="" />
      <div class="invalid-feedback">
        Please provide a valid meta title.
      </div>
    </div>

    <div class="form-group">
      <label for="meta_description">Meta Description (SEO):</label>
      <textarea v-model="blog.meta_description" class="form-control" name="meta_description" id="meta_description"
        placeholder=""></textarea>
      <div class="invalid-feedback">
        Please provide a valid meta description.
      </div>
    </div>
    <div class="form-group">
      <label for="body">Release Date:</label>
      <datepicker placeholder="Release Date" v-model="blog.date_released" name="release_date"
        input-class="form-control"></datepicker>
    </div>
    <div class="form-group">
      <label for="thumbnail">Thumbnail:</label>
      <input type="text" placeholder="Amazon S3 URL" class="form-control" v-model="blog.thumbnail" />
    </div>
    <div class="form-group">
      <label for="excerpt">Excerpt</label>
      <textarea class="form-control" rows="4" v-model="blog.excerpt"></textarea>
    </div>
    <div class="form-group">
      <label for="body">Body:</label>
      <editor api-key="h6ymu878nq2dmit84wddqdm84to1x90pryg1iec6s96rph86" name="body" id="body" v-model="blog.post"
        class="wysiwyg-editor" :init="{
          plugins:
            'wordcount link image fullscreen lists textcolor colorpicker a11ychecker advcode formatpainter linkchecker media mediaembed powerpaste tinydrive tinymcespellchecker',
        }"
        toolbar="fontselect | undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | table | fontsizeselect a11ycheck code formatpainter insertfile">
      </editor>
    </div>
    <div class="form-group signup-carousel-buttons">
      <button class="btn btn-primary">Save</button>
    </div>
  </form>
</template>

<style>
#body {
  height: 400px;
}

.wysiwyg-editor {
  height: 400px;
}
</style>

<script>
import Editor from "@tinymce/tinymce-vue";
import BlogService from "../../services/BlogService";
import Datepicker from "vuejs-datepicker";

export default {
  data: function () {
    return {
      blog: {},
    };
  },

  created: function () {
    if (!isNaN(this.$route.params.id)) {
      BlogService.findById(this.$route.params.id).then((response) => {
        this.blog = response.data;
      });
    } else if (this.$route.params.id !== "add") {
      alert("Invalid parameter.");
    }
  },

  components: {
    editor: Editor,
    datepicker: Datepicker,
  },

  methods: {
    saveChanges() {
      if (!isNaN(this.$route.params.id)) {
        BlogService.update(this.$route.params.id, this.blog).then(
          (response) => {
            alert("Blog Post Updated");
          }
        );
      } else if (this.$route.params.id == "add") {
        BlogService.create(this.blog).then((response) => {
          alert("Blog Post Created");
        });
      }
    },
  },
};
</script>
