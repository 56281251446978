import Vue from "vue";
import AccountService from "./AccountService";

export default {
  create(organization) {
    let apiMethodUrl = process.env.VUE_APP_ROOT_API + "/organizations";
    let organizationPayload = {};
    let options = AccountService.getSessionHeaders();
    organizationPayload.name = organization.name;
    organizationPayload.address = organization.address;
    organizationPayload.country = organization.country;
    organizationPayload.phone = organization.phone;
    organizationPayload.seats = organization.seats;
    organizationPayload.cec_count = organization.cec_count;

    return Vue.axios.post(apiMethodUrl, organizationPayload, options);
  },

  update(id, organization) {
    let apiMethodUrl = process.env.VUE_APP_ROOT_API + "/organizations/" + id;
    let organizationPayload = {};
    let options = AccountService.getSessionHeaders();
    organizationPayload.name = organization.name;
    organizationPayload.address = organization.address;
    organizationPayload.country = organization.country;
    organizationPayload.phone = organization.phone;
    organizationPayload.seats = organization.seats;
    organizationPayload.cec_count = organization.cec_count;

    return Vue.axios.patch(apiMethodUrl, organizationPayload, options);
  },

  delete(id) {
    let apiMethodUrl = process.env.VUE_APP_ROOT_API + "/organizations/" + id;
    let options = AccountService.getSessionHeaders();

    return Vue.axios.delete(apiMethodUrl, options);
  },

  all() {
    let apiMethodUrl = process.env.VUE_APP_ROOT_API + "/organizations";
    return Vue.axios.get(apiMethodUrl);
  },

  findById(id) {
    let apiMethodUrl = process.env.VUE_APP_ROOT_API + "/organizations/" + id;
    return Vue.axios.get(apiMethodUrl);
  },
};
