<template>
  <div class="banners">
    <div class="modal fade" id="deleteRecordModal" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              Confirm you would like to delete this banner!
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>
              Are you sure you would like to delete this banner?
            </p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              @click="saveDeleteRecord"
            >
              Yes
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              No
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom"
    >
      <h1 class="h2">Banners</h1>
    </div>
    <table class="table">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Title</th>
          <th scope="col">
            <router-link :to="{ path: '/banners/add' }"
              ><button type="button" class="btn btn-info">
                Add
              </button></router-link
            >
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(banner, index) in banners" v-bind:key="banner.id">
          <th scope="row">{{ banner.id }}</th>
          <td>{{ banner.banner_description }}</td>
          <td>
            <router-link :to="{ path: 'banners/' + banner.id }"
              ><button type="button" class="btn btn-info">
                Edit
              </button></router-link
            >
            <button
              type="button"
              class="btn btn-danger"
              @click="confirmDeleteRecord($event, banner.id, index)"
            >
              Delete
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import $ from "jquery";
import BannerService from "../../services/BannerService";

export default {
  data() {
    return {
      banners: [],
      deleteRecord: {
        index: 0,
        id: 0
      }
    };
  },

  methods: {
    saveDeleteRecord() {
      var $this = this;
        BannerService.delete(this.deleteRecord.id)
          .then(response => {
            console.log(response);
            //$('#deleteRecordModal').modal('hide');
            document.querySelectorAll("[data-dismiss=modal]")[0].click();
            $this.banners.splice($this.deleteRecord.index, 1);
          });
    },

    confirmDeleteRecord(event, id, index) {
      this.deleteRecord = {
        id: id,
        index: index
      };

      $("#deleteRecordModal").modal({});
    }
  },

  created() {
    BannerService.all().then(response => {
      this.banners = response.data;
    });
  }
};
</script>
<style></style>
