<template>
  <div class="stats">
    <table class="table table-sm table-striped table-dark">
      <tbody>
        <tr>
          <td scope="row">Instructor Banner</td>
          <td>1920x1080</td>
        </tr>
        <tr>
          <td scope="row">Instructor Thumbnail</td>
          <td>600x600</td>
        </tr>
        <tr>
          <td scope="row">Instructor Action Shot</td>
          <td>450px width</td>
        </tr>
        <tr>
          <td scope="row">Video Category Banner</td>
          <td>1920x1080</td>
        </tr>
        <tr>
          <td scope="row">Blog Thumbnail</td>
          <td>600 Width</td>
        </tr>
        <tr>
          <td scope="row">Video Page Top Banner</td>
          <td>1600x900</td>
        </tr>
        <tr>
          <td scope="row">Home Page What Is FM Banner</td>
          <td>1920x900</td>
        </tr>
        <tr>
          <td scope="row">Home Page Top Banner</td>
          <td>1920x600</td>
        </tr>
        <tr>
          <td scope="row">Home Page Top Banner</td>
          <td>1920x600</td>
        </tr>
        <tr>
          <td scope="row">Home Poolfit Banner</td>
          <td>1920x600</td>
        </tr>
      </tbody>
    </table>

    <h2>Found out From Referral Stats</h2>
    <form class="form-inline date-range-form" v-on:submit.prevent="filterReferralStats">
      <div class="form-group">
        <label for="subscribed_on_start_date">Subscribed Start Date:</label>
        <datepicker placeholder="Subscribed Start Date" v-model="subscribed_on_start_date"
          name="subscribed_on_start_date" input-class="form-control"></datepicker>
      </div>
      <div class="form-group">
        <label for="subscribed_on_end_date">Subscribed End Date:</label>
        <datepicker placeholder="Subscribed End Date" v-model="subscribed_on_end_date" name="subscribed_on_end_date"
          input-class="form-control"></datepicker>
      </div>
      <div class="form-group">
        <button type="submit" class="btn btn-primary ml-2">Apply</button>
      </div>
    </form>
    <table class="table table-sm table-striped table-dark">
      <tbody>
        <tr>
          <td scope="row">Found out From:</td>
          <td>Count</td>
        </tr>
        <tr v-for="referral in referrals" v-bind:key="referral.found_out_from">
          <td scope="row">{{ referral.found_out_from }}</td>
          <td>{{ referral.count }}</td>
        </tr>
      </tbody>
    </table>

    <h2>Users Cancelled Stats</h2>
    <form class="form-inline date-range-form">
      <div class="form-group">
        <label>Cancellation Period: </label>
        <vue-monthly-picker 
          v-model="selectedMonth"
          placeHolder="Select month"
          v-bind:monthLabels="monthLabels"
          v-on:selected="filterCancelledStats"></vue-monthly-picker>
      </div>
    </form>
    <table class="table table-sm table-striped table-dark">
      <tbody>
        <tr>
          <td scope="row">Name:</td>
          <td>Email: </td>
          <td>Plan: </td>
        </tr>
        <tr v-for="user in cancelledUsers" v-bind:key="user.fullname">
          <td scope="row">{{ user.fullname }}</td>
          <td>{{ user.email }}</td>
          <td>{{ user.plan }}</td>
        </tr>
      </tbody>
    </table>

    <h2>Country Stats</h2>
    <table class="table table-sm table-striped table-dark">
      <tbody>
        <tr>
          <td scope="row">Total Countries:</td>
          <td>{{ totalCountries }}</td>
        </tr>
        <tr v-for="country in countries" v-bind:key="country.country">
          <td scope="row">{{ country.country }}</td>
          <td>{{ country.country_count }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<style scoped>
.date-range-form {
  margin: 15px 0;
}
.date-range-form label {
  margin: 0 10px 0 25px;
}
</style>

<script>
import Datepicker from "vuejs-datepicker";
import VueMonthlyPicker from 'vue-monthly-picker'
import StatsService from "../services/StatsService";

export default {

  components: {
    datepicker: Datepicker,
    VueMonthlyPicker,
  },

  methods: {
    filterReferralStats() {
      StatsService.getCustomerReferralStats(this.subscribed_on_start_date, this.subscribed_on_end_date).then(response => {
        let data = response.data;

        this.referrals = data.content;
      });
    },

    filterCancelledStats(e) {
      StatsService.getCustomerCancelledStats(e._i.split("/")[1], e._i.split("/")[0]).then(response => {
        let data = response.data;

        console.log('getCustomerCancelledStats response >>>>>>> ', data)

        this.cancelledUsers = data.content;
      });
    },
  },

  data: function () {
    return {
      subscribed_on_start_date: undefined,
      subscribed_on_end_date: undefined,
      countries: [],
      totalCountries: 0,
      plans: [],
      referrals: {},
      selectedMonth: '',
      monthLabels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
      cancelledUsers: {}
    };
  },

  mounted: function () {
    StatsService.all().then((response) => {
      let data = response.data.data;
      this.countries = data.Countries;
      this.totalCountries = data.TotalCountries;

      for (let plan of data.Plans) {
        if (plan.plan === "fmpremium02") {
          plan.plan = "Premium Pro Annual";
        } else if (plan.plan === "fmpremium01") {
          plan.plan = "Premium Pro Monthly";
        } else if (plan.plan === "fmbasic02") {
          plan.plan = "Basic Pro Annual";
        } else if (plan.plan === "fmbasic01") {
          plan.plan = "Basic Pro Annual";
        }

        this.plans.push(plan);
      }
    });

    this.filterReferralStats().then(response => {
      let data = response.data;
      this.referrals = data.content;
    });

    let dateObj = new Date();
    let month = dateObj.getUTCMonth() + 1;
    let year = dateObj.getUTCFullYear();
    let newdate = year + "/" + month;

    this.filterCancelledStats({_i: newdate});
  },
};
</script>
