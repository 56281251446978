import Vue from "vue";
import Router from "vue-router";
import MainView from "./views/Main.vue";

Vue.use(Router);

export default new Router({
  mode: "history",
  saveScrollPosition: true,
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/login",
      name: "login",
      component: () => import("./views/Login.vue"),
    },
    {
      path: "/",
      component: Vue.component("admin-main", MainView),
      children: [
        {
          path: "/",
          name: "dashboard",
          component: () => import("./views/Dashboard.vue"),
        },

        // Blog Routes
        {
          path: "/blogs",
          name: "blogs",
          component: () => import("./views/blogs/BlogList.vue"),
        },

        {
          path: "/blogs/:id",
          name: "blogs-update",
          component: () => import("./views/blogs/BlogForm.vue"),
        },

        {
          path: "/blogs/add",
          name: "blogs-add",
          component: () => import("./views/blogs/BlogForm.vue"),
        },

        // Video Routes
        {
          path: "/videos",
          name: "videos",
          component: () => import("./views/videos/VideoList.vue"),
        },

        {
          path: "/videos/:id",
          name: "videos-update",
          component: () => import("./views/videos/VideoForm.vue"),
        },

        {
          path: "/videos/add",
          name: "videos-add",
          component: () => import("./views/videos/VideoForm.vue"),
        },

        // Audio Routes
        {
          path: "/audios",
          name: "audios",
          component: () => import("./views/audios/AudioList.vue"),
        },

        {
          path: "/audios/:id",
          name: "audios-update",
          component: () => import("./views/audios/AudioForm.vue"),
        },

        {
          path: "/audios/add",
          name: "audios-add",
          component: () => import("./views/audios/AudioForm.vue"),
        },

         // Banner Routes
         {
          path: "/banners",
          name: "banners",
          component: () => import("./views/banners/BannerList.vue"),
        },

        {
          path: "/banners/:id",
          name: "banners-update",
          component: () => import("./views/banners/BannerForm.vue"),
        },

        {
          path: "/banners/add",
          name: "banners-add",
          component: () => import("./views/banners/BannerForm.vue"),
        },

        // Organization Routes
        {
          path: "/organizations",
          name: "organizations",
          component: () => import("./views/organizations/OrganizationList.vue"),
        },

        {
          path: "/organizations/:id",
          name: "organizations-update",
          component: () => import("./views/organizations/OrganizationForm.vue"),
        },

        {
          path: "/organizations/add",
          name: "organizations-add",
          component: () => import("./views/organizations/OrganizationForm.vue"),
        },

        // Testimonial Routes
        {
          path: "/testimonials",
          name: "testimonials",
          component: () => import("./views/testimonials/TestimonialList.vue"),
        },

        {
          path: "/testimonials/:id",
          name: "testimonials-update",
          component: () => import("./views/testimonials/TestimonialForm.vue"),
        },

        {
          path: "/testimonials/add",
          name: "testimonials-add",
          component: () => import("./views/testimonials/TestimonialForm.vue"),
        },

        // Tip Routes
        {
          path: "/tips",
          name: "tips",
          component: () => import("./views/tips/TipList.vue"),
        },

        {
          path: "/tips/:id",
          name: "tips-update",
          component: () => import("./views/tips/TipForm.vue"),
        },

        {
          path: "/tips/add",
          name: "tips-add",
          component: () => import("./views/tips/TipForm.vue"),
        },

        // Instructor Routes
        {
          path: "/instructors",
          name: "instructors",
          component: () => import("./views/instructors/InstructorList.vue"),
        },

        {
          path: "/instructors/:id",
          name: "instructors-update",
          component: () => import("./views/instructors/InstructorForm.vue"),
        },

        {
          path: "/instructors/add",
          name: "instructors-add",
          component: () => import("./views/instructors/InstructorForm.vue"),
        },

        // Page Routes
        {
          path: "/pages",
          name: "pages",
          component: () => import("./views/pages/PageList.vue"),
        },

        {
          path: "/pages/:id",
          name: "pages-update",
          component: () => import("./views/pages/PageForm.vue"),
        },

        // Quiz Routes
        {
          path: "/quizzes",
          name: "quizzes",
          component: () => import("./views/quizzes/QuizList.vue"),
        },

        {
          path: "/quizzes/:id",
          name: "quizzes-update",
          component: () => import("./views/quizzes/QuizForm.vue"),
        },

        {
          path: "/quizzes/add",
          name: "quizzes-add",
          component: () => import("./views/quizzes/QuizForm.vue"),
        },

        // Purchase Routes
        {
          path: "/purchases",
          name: "purchases",
          component: () => import("./views/purchases/PurchaseList.vue"),
        },

        // User Routes
        {
          path: "/users",
          name: "users",
          component: () => import("./views/users/UserList.vue"),
        },

        {
          path: "/users/:id",
          name: "users-update",
          component: () => import("./views/users/UserForm.vue"),
        },

        {
          path: "/users/password/:id",
          name: "users-password",
          component: () => import("./views/users/UserPassword.vue"),
        },

        // Video Category Routes
        {
          path: "/video-categories",
          name: "video-categories",
          component: () =>
            import("./views/videos-categories/VideoCategoriesList.vue"),
        },

        {
          path: "/video-categories/:id",
          name: "video-categories-update",
          component: () =>
            import("./views/videos-categories/VideoCategoriesForm.vue"),
        },

        {
          path: "/video-categories/add",
          name: "video-categories-add",
          component: () =>
            import("./views/videos-categories/VideoCategoriesForm.vue"),
        },

        // Assign Related Video Routes
        {
          path: "/related-videos",
          name: "related-videos",
          component: () =>
            import("./views/videos-related/VideoRelatedList.vue"),
        },

        {
          path: "/related-videos/:id",
          name: "related-videos-update",
          component: () =>
            import("./views/videos-related/VideoRelatedForm.vue"),
        },

        // Assign Categories to Videos Routes
        {
          path: "/categorize-videos",
          name: "categorize-videos",
          component: () =>
            import("./views/videos-categorization/VideoCategorizationList.vue"),
        },

        {
          path: "/categorize-videos/:id",
          name: "categorize-videos-update",
          component: () =>
            import("./views/videos-categorization/VideoCategorizationForm.vue"),
        },

        // Assign Instructors to Videos Routes
        {
          path: "/instructor-videos",
          name: "instructor-videos",
          component: () =>
            import("./views/videos-instructors/VideoInstructorsList.vue"),
        },

        {
          path: "/instructor-videos/:id",
          name: "instructor-videos-update",
          component: () =>
            import("./views/videos-instructors/VideoInstructorsForm.vue"),
        },

        // Notes Routes
        {
          path: "/notes",
          name: "notes",
          component: () => import("./views/notes/NotesList.vue"),
        },

        {
          path: "/notes/:noteId",
          name: "notes-update",
          component: () => import("./views/notes/NotesForm.vue"),
        },

        {
          path: "/notes/add",
          name: "notes-add",
          component: () => import("./views/notes/NotesForm.vue"),
        },

        {
          path: "/notes/preview/:noteId",
          name: "notes-preview",
          component: () => import("./views/notes/NotesPreview.vue"),
        },

        // Util Routes
        {
          path: "/cloudfront-cdn-link-generator",
          name: "cloudfront-link-generator",
          component: () => import("./views/utils/cloudfrontLinkGen.vue"),
        }
      ],
    },
  ],
});
