import Vue from "vue";
import AccountService from "./AccountService";

export default {
  create(video) {
    let apiMethodUrl = process.env.VUE_APP_ROOT_API + "/videos";
    let options = AccountService.getSessionHeaders();
    let videoPayload = {};
    videoPayload.trial = video.trial;
    videoPayload.title = video.title;
    videoPayload.meta_description = video.metaDescription;
    videoPayload.meta_title = video.metaTitle;
    videoPayload.body = video.body;
    videoPayload.videoUrl = video.videoUrl;
    videoPayload.previewUrl = video.previewUrl;
    videoPayload.thumbnail = video.thumbnail;
    videoPayload.dateReleased = video.dateReleased;
    videoPayload.level = video.level;
    videoPayload.blogLink = video.blogLink;
    videoPayload.cecPurchaseLink = video.cecPurchaseLink;
    videoPayload.audioPurchaseLink = video.audioPurchaseLink;
    videoPayload.handoutLink = video.handoutLink;
    videoPayload.premium_download_available = video.premium_download_available;
    videoPayload.keywords = video.keywords;

    return Vue.axios.post(apiMethodUrl, videoPayload, options);
  },

  update(id, video) {
    let apiMethodUrl = process.env.VUE_APP_ROOT_API + "/videos/" + id;
    let options = AccountService.getSessionHeaders();
    let videoPayload = {};
    videoPayload.trial = video.trial;
    videoPayload.title = video.title;
    videoPayload.meta_description = video.metaDescription;
    videoPayload.meta_title = video.metaTitle;
    videoPayload.body = video.body;
    videoPayload.videoUrl = video.videoUrl;
    videoPayload.previewUrl = video.previewUrl;
    videoPayload.thumbnail = video.thumbnail;
    videoPayload.dateReleased = video.dateReleased;
    videoPayload.level = video.level;
    videoPayload.blogLink = video.blogLink;
    videoPayload.cecPurchaseLink = video.cecPurchaseLink;
    videoPayload.audioPurchaseLink = video.audioPurchaseLink;
    videoPayload.handoutLink = video.handoutLink;
    videoPayload.premium_download_available = video.premium_download_available;
    videoPayload.keywords = video.keywords;

    console.log(videoPayload);

    return Vue.axios.patch(apiMethodUrl, videoPayload, options);
  },

  delete(id) {
    let apiMethodUrl = process.env.VUE_APP_ROOT_API + "/videos/" + id;
    let options = AccountService.getSessionHeaders();

    return Vue.axios.delete(apiMethodUrl, options);
  },

  findById(id) {
    let apiMethodUrl = process.env.VUE_APP_ROOT_API + "/videos/" + id;
    let options = AccountService.getSessionHeaders();

    return Vue.axios.get(apiMethodUrl, options);
  },

  all() {
    let apiMethodUrl = process.env.VUE_APP_ROOT_API + "/videos";
    let options = AccountService.getSessionHeaders();

    return Vue.axios.get(apiMethodUrl, options);
  },

  released() {
    let apiMethodUrl = process.env.VUE_APP_ROOT_API + "/videos?released=1";
    let options = AccountService.getSessionHeaders();

    return Vue.axios.get(apiMethodUrl, options);
  },
};
