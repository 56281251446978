<template>
  <div class="video-categories-list">
    <div class="modal fade" id="deleteRecordModal" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              Confirm you would like to delete this video category!
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>Are you sure you would like to delete this video category?</p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              @click="saveDeleteRecord"
            >
              Yes
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              No
            </button>
          </div>
        </div>
      </div>
    </div>
    <table class="table-striped table table-hover table-condensed">
      <tr>
        <th>Name</th>
        <th>Slug</th>
        <th>
          <router-link :to="{ path: '/video-categories/add' }"
            ><button class="btn btn-primary pull-right">
              Add
            </button></router-link
          >
        </th>
      </tr>
      <tr v-for="(category, index) in categories" v-bind:key="category.id">
        <td>{{ category.name }}</td>
        <td>{{ category.slug }}</td>
        <td style="width: 200px">
          <router-link :to="{ path: '/video-categories/' + category.id }"
            ><button class="btn btn-success btn-xs">Edit</button></router-link
          >
          <button
            class="btn btn-danger btn-xs"
            @click="confirmDeleteRecord($event, category.id, index)"
          >
            Delete
          </button>
        </td>
      </tr>
    </table>
  </div>
</template>

<style></style>

<script>
import VideoCategoryService from "../../services/VideoCategoryService";

export default {
  data: function () {
    return {
      categories: [],
      deleteRecord: {
        index: 0,
        id: 0,
      },
    };
  },

  created: function () {
    VideoCategoryService.all().then((response) => {
      this.categories = response.data;
    });
  },

  methods: {
    confirmDeleteRecord: function (event, id, index) {
      this.deleteRecord = {
        id: id,
        index: index,
      };

      $("#deleteRecordModal").modal({});
    },

    saveDeleteRecord: function () {
      VideoCategoryService.delete(this.deleteRecord.id).then((response) => {
        console.log(response);
        //$('#deleteRecordModal').modal('hide');
        $("[data-dismiss=modal]").trigger({ type: "click" });
        this.categories.splice(this.deleteRecord.index, 1);
      });
    },
  },
};
</script>
