<template>
  <div class="video-categories-form" v-on:submit.prevent="saveChanges">
    <div
      class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      <h1 class="h2">Category</h1>
    </div>
    <form class="form-horizontal">
      <div class="form-group">
        <label for="popular">Popular:</label>
        <select class="form-control" name="popular" v-model="category.popular">
          <option value="0">No</option>
          <option value="1">
            Yes
          </option>
        </select>
      </div>
      <div class="form-group">
        <label for="category">Category:</label>
        <input type="text" name="category" v-model="category.name" placeholder="Category name" class="form-control" />
      </div>
      <div class="form-group">
        <label for="category_slug">Category Slug:</label>
        <input type="text" name="category_slug" v-model="category.slug" placeholder="Category slug"
          class="form-control" />
      </div>

      <div class="form-group">
        <label for="body">Parent Category:</label>
        <select class="form-control" name="video" v-model="category.parent">
          <option value="">.. Do not set</option>
          <option v-for="parent in categories" v-bind:value="parent.id" v-bind:key="parent.id">
            {{ parent.name }}
          </option>
        </select>
      </div>

      <div class="form-group">
        <label for="category_type">Header Placement:</label>
        <select class="form-control" name="category_type" v-model="category.header_placement">
          <option value="">.. Do not set</option>
          <option v-for="placementOption in headerPlacementOptions" v-bind:value="placementOption.value"
            v-bind:key="placementOption.value">
            {{ placementOption.title }}
          </option>
        </select>
      </div>

      <div class="form-group">
        <label for="category_type">Sub Header Placement:</label>
        <select class="form-control" name="category_type" v-model="category.sub_header_placement">
          <option value="">.. Do not set</option>
          <option v-for="placementOption in headerPlacementOptions" v-bind:value="placementOption.value"
            v-bind:key="placementOption.value">
            {{ placementOption.title }}
          </option>
        </select>
      </div>

      <div class="form-group">
        <label for="category_type">Category Type:</label>
        <select class="form-control" name="category_type" v-model="category.type">
          <option value="">.. Do not set</option>
          <option v-for="categoryType in categoryTypes" v-bind:value="categoryType.value"
            v-bind:key="categoryType.value">
            {{ categoryType.title }}
          </option>
        </select>
      </div>

      <div class="form-group">
        <label for="sort_order">Sort Order:</label>
        <input type="text" v-model="category.sort_order" class="form-control" name="sort_order" id="sort_order"
          placeholder="" />
        <div class="invalid-feedback">Please provide a valid sort order.</div>
      </div>

      <div class="form-group">
        <label for="category_hero">Hero Image:</label>
        <input type="text" v-model="category.hero" class="form-control" name="category_hero" id="category_hero"
          placeholder="" />
        <div class="invalid-feedback">
          Please provide a valid category hero image url.
        </div>
      </div>

      <div class="form-group">
        <label for="description">Description:</label>
        <textarea v-model="category.description" class="form-control" name="description" id="description"
          placeholder=""></textarea>
        <div class="invalid-feedback">Please provide a valid description.</div>
      </div>

      <div class="form-group">
        <label for="meta_title">Meta Title (SEO):</label>
        <input type="text" v-model="category.meta_title" class="form-control" name="meta_title" id="meta_title"
          placeholder="" />
        <div class="invalid-feedback">Please provide a valid meta title.</div>
      </div>

      <div class="form-group">
        <label for="meta_description">Meta Description (SEO):</label>
        <textarea v-model="category.meta_description" class="form-control" name="meta_description" id="meta_description"
          placeholder=""></textarea>
        <div class="invalid-feedback">
          Please provide a valid meta description.
        </div>
      </div>
      <div class="form-group signup-carousel-buttons">
        <button class="btn btn-primary">Save</button>
      </div>
    </form>
  </div>
</template>

<style>
</style>

<script>
import VideoCategoryService from "../../services/VideoCategoryService";
import CategoryTypes from "../../data/category-types.json";
import HeaderPlacementOptions from "../../data/header-placement-options.json";

export default {
  data: function () {
    return {
      categoryTypes: CategoryTypes,
      headerPlacementOptions: HeaderPlacementOptions,
      categories: [],
      category: {
        name: ""
      },
    };
  },

  async created() {
    let categoriesResponse = await VideoCategoryService.all();
    this.categories = categoriesResponse.data;


    if (!isNaN(this.$route.params.id)) {
      let categoryRecordResponse = await VideoCategoryService.findById(this.$route.params.id);
      this.category = categoryRecordResponse.data;
    } else if (this.$route.params.id !== "add") {
      alert("Invalid parameter.");
    }
  },

  methods: {
    saveChanges: function () {
      if (!isNaN(this.$route.params.id)) {
        VideoCategoryService.update(this.$route.params.id, this.category).then(
          (data) => {
            alert("Category updated");
          }
        );
      } else if (this.$route.params.id == "add") {
        VideoCategoryService.create(this.category).then((data) => {
          alert("Category created.");
        });
      }
    },
  },
};
</script>
