<template>
  <div class="bannerForm">

    <form v-on:submit.prevent="saveChanges">
      <div class="form-group">
        <label for="banner_id">Banner Identifier:</label>
        <input type="text" v-model="banner.banner_id"
          :class="{ 'form-control': true, 'is-invalid': errors.has('banner_id') }" name="banner_id" id="banner_id"
          placeholder="Banner Identifier" v-validate="'required'" />
        <div class="invalid-feedback">
          Please provide a valid banner identifier that is unique.
        </div>
      </div>

      <div class="form-group">
        <label for="page_id">Page Identifier:</label>
        <input type="text" v-model="banner.page_id"
          :class="{ 'form-control': true, 'is-invalid': errors.has('page_id') }" name="page_id" id="page_id"
          placeholder="Page Identifier" v-validate="'required'" />
        <div class="invalid-feedback">
          Please provide a valid page identifier that is unique.
        </div>
      </div>

      <div class="form-group">
        <label for="banner_description">Short Description:</label>
        <input type="text" v-model="banner.banner_description" :class="{
          'form-control': true,
          'is-invalid': errors.has('banner_description')
        }" name="banner_description" id="banner_description" placeholder="" v-validate="'required'" />
        <div class="invalid-feedback">
          Please provide a valid banner description.
        </div>
      </div>

      <div class="form-group">
        <label for="video">Type:</label>
        <select class="form-control" name="thumbnail_grid" v-model="banner.banner_type">
          <option v-for="bannerType in bannerTypes" v-bind:value="bannerType.value" v-bind:key="bannerType.title">
            {{ bannerType.title }}
          </option>
        </select>
      </div>

      <fieldset v-if="banner.banner_type == 'banner'">
        <legend>Banner Data</legend>

        <div class="form-group">
          <label for="bd_header_image">Header Image:</label>
          <input type="text" v-model="banner.banner_data.header_image"
            :class="{ 'form-control': true, 'is-invalid': errors.has('bd_header_image') }" name="bd_header_image"
            id="bd_header_image" placeholder="Banner Header" v-validate="'required'" />
          <div class="invalid-feedback">
            Please provide a valid header image url 1920x1080.
          </div>
        </div>
        <div class="form-group">
          <label for="bd_header">Header:</label>
          <input type="text" v-model="banner.banner_data.header"
            :class="{ 'form-control': true, 'is-invalid': errors.has('bd_header') }" name="bd_header" id="bd_header"
            placeholder="Banner Header" v-validate="'required'" />
          <div class="invalid-feedback">
            Please provide a valid banner header title.
          </div>
        </div>

        <div class="form-group">
          <label for="bd_header_placement">Header Placement:</label>
          <select class="form-control" name="bd_header_placement" v-model="banner.banner_data.header_placement">
            <option value="">.. Do not set</option>
            <option v-for="placementOption in headerPlacementOptions" v-bind:value="placementOption.value"
              v-bind:key="placementOption.value">
              {{ placementOption.title }}
            </option>
          </select>
        </div>

        <div class="form-group">
          <label for="bd_sub_header">Sub Header:</label>
          <input type="text" v-model="banner.banner_data.sub_header"
            :class="{ 'form-control': true, 'is-invalid': errors.has('bd_sub_header') }" name="bd_sub_header"
            id="bd_sub_header" placeholder="Banner Sub Header" v-validate="'required'" />
          <div class="invalid-feedback">
            Please provide a valid banner sub header title.
          </div>
        </div>

        <div class="form-group">
          <label for="bd_sub_header_placement">Sub Header Placement:</label>
          <select class="form-control" name="bd_sub_header_placement" v-model="banner.banner_data.sub_header_placement">
            <option value="">.. Do not set</option>
            <option v-for="placementOption in headerPlacementOptions" v-bind:value="placementOption.value"
              v-bind:key="placementOption.value">
              {{ placementOption.title }}
            </option>
          </select>
        </div>


      </fieldset>


      <fieldset v-if="banner.banner_type == 'featured_video_banner'">
        <legend>Banner Data</legend>

        <div class="form-group">
          <label for="bd_header_image">Header Image:</label>
          <input type="text" v-model="banner.banner_data.header_image"
            :class="{ 'form-control': true, 'is-invalid': errors.has('bd_header_image') }" name="bd_header_image"
            id="bd_header_image" placeholder="Banner Header" v-validate="'required'" />
          <div class="invalid-feedback">
            Please provide a valid header image url 1920x1080.
          </div>
        </div>
        <div class="form-group">
          <label for="bd_header">Header:</label>
          <input type="text" v-model="banner.banner_data.header"
            :class="{ 'form-control': true, 'is-invalid': errors.has('bd_header') }" name="bd_header" id="bd_header"
            placeholder="Banner Header" v-validate="'required'" />
          <div class="invalid-feedback">
            Please provide a valid banner header title.
          </div>
        </div>

        <div class="form-group">
          <label for="bd_description">Description:</label>
          <textarea rows="10" cols="50" v-model="banner.banner_data.description" :class="{
            'form-control': true,
            'is-invalid': errors.has('bd_description')
          }" name="bd_description" id="bd_description" placeholder="" v-validate="'required'">
          </textarea>
          <div class="invalid-feedback">
            Please provide a valid description.
          </div>
        </div>

        <div class="form-group">
          <label for="bd_starring">Starring:</label>
          <input type="text" v-model="banner.banner_data.starring"
            :class="{ 'form-control': true, 'is-invalid': errors.has('bd_starring') }" name="bd_starring"
            id="bd_starring" placeholder="Starring" v-validate="'required'" />
          <div class="invalid-feedback">
            Please provide a valid starring.
          </div>
        </div>

        <div class="form-group">
          <label for="bd_category">Category:</label>
          <input type="text" v-model="banner.banner_data.category"
            :class="{ 'form-control': true, 'is-invalid': errors.has('bd_category') }" name="bd_category"
            id="bd_category" placeholder="Category" v-validate="'required'" />
          <div class="invalid-feedback">
            Please provide a valid banner category.
          </div>
        </div>

        <div class="form-group">
          <label for="bd_keywords">Keywords:</label>
          <input type="text" v-model="banner.banner_data.keywords"
            :class="{ 'form-control': true, 'is-invalid': errors.has('bd_keywords') }" name="bd_keywords"
            id="bd_keywords" placeholder="Keywords" v-validate="'required'" />
          <div class="invalid-feedback">
            Please provide a valid banner keywords.
          </div>
        </div>

        <div class="form-group">
          <label for="bd_video_link">Video Link:</label>
          <input type="text" v-model="banner.banner_data.video_link"
            :class="{ 'form-control': true, 'is-invalid': errors.has('bd_video_link') }" name="bd_video_link"
            id="bd_video_link" placeholder="Keywords" v-validate="'required'" />
          <div class="invalid-feedback">
            Please provide a valid banner video link.
          </div>
        </div>

        <div class="form-group">
          <label for="bd_blog_link">Video Blog Link:</label>
          <input type="text" v-model="banner.banner_data.blog_link"
            :class="{ 'form-control': true, 'is-invalid': errors.has('bd_blog_link') }" name="bd_blog_link"
            id="bd_blog_link" placeholder="Keywords" v-validate="'required'" />
          <div class="invalid-feedback">
            Please provide a valid banner blog link.
          </div>
        </div>

        <div class="form-group">
          <label for="upcoming_video01">Upcoming Video #1:</label>
          <input type="text" v-model="banner.banner_data.upcoming_video01"
            :class="{ 'form-control': true, 'is-invalid': errors.has('upcoming_video01') }" name="upcoming_video01"
            id="upcoming_video01" placeholder="Upcoming Video #1" />
          <div class="invalid-feedback">
            Please provide a valid upcoming video #1.
          </div>
        </div>

        <div class="form-group">
          <label for="upcoming_video02">Upcoming Video #2:</label>
          <input type="text" v-model="banner.banner_data.upcoming_video02"
            :class="{ 'form-control': true, 'is-invalid': errors.has('upcoming_video02') }" name="upcoming_video02"
            id="upcoming_video02" placeholder="Upcoming Video #2" />
          <div class="invalid-feedback">
            Please provide a valid upcoming video #2.
          </div>
        </div>

        <div class="form-group">
          <label for="upcoming_video03">Upcoming Video #3:</label>
          <input type="text" v-model="banner.banner_data.upcoming_video03"
            :class="{ 'form-control': true, 'is-invalid': errors.has('upcoming_video03') }" name="upcoming_video03"
            id="upcoming_video03" placeholder="Upcoming Video #3" />
          <div class="invalid-feedback">
            Please provide a valid upcoming video #3.
          </div>
        </div>
      </fieldset>

      <fieldset v-if="banner.banner_type == 'behind_scenes'">
        <legend>Banner Data</legend>

        <div class="form-group">
          <label for="bd_header_image">Header Image:</label>
          <input type="text" v-model="banner.banner_data.header_image"
            :class="{ 'form-control': true, 'is-invalid': errors.has('bd_header_image') }" name="bd_header_image"
            id="bd_header_image" placeholder="Banner Header" v-validate="'required'" />
          <div class="invalid-feedback">
            Please provide a valid header image url 1920x1080.
          </div>
        </div>
        <div class="form-group">
          <label for="bd_header">Header:</label>
          <input type="text" v-model="banner.banner_data.header"
            :class="{ 'form-control': true, 'is-invalid': errors.has('bd_header') }" name="bd_header" id="bd_header"
            placeholder="Banner Header" v-validate="'required'" />
          <div class="invalid-feedback">
            Please provide a valid banner header title.
          </div>
        </div>

        <div class="form-group">
          <label for="bd_description">Description:</label>
          <textarea rows="10" cols="50" v-model="banner.banner_data.description" :class="{
            'form-control': true,
            'is-invalid': errors.has('bd_description')
          }" name="bd_description" id="bd_description" placeholder="" v-validate="'required'">
          </textarea>
          <div class="invalid-feedback">
            Please provide a valid description.
          </div>
        </div>

        <div class="form-group">
          <label for="bd_video_embed_link">Video Embed Link:</label>
          <input type="text" v-model="banner.banner_data.video_embed_link"
            :class="{ 'form-control': true, 'is-invalid': errors.has('bd_video_embed_link') }"
            name="bd_video_embed_link" id="bd_video_embed_link" placeholder="Video Embed Link"
            v-validate="'required'" />
          <div class="invalid-feedback">
            Please provide a valid banner header title.
          </div>
        </div>

        <div class="form-group">
          <label for="bd_video_call_to_action_title">Call to Action Title:</label>
          <input type="text" v-model="banner.banner_data.call_to_action.title"
            :class="{ 'form-control': true, 'is-invalid': errors.has('bd_video_call_to_action_title') }"
            name="bd_video_call_to_action_title" id="bd_video_call_to_action_title" placeholder="Call to action title"
            v-validate="'required'" />
          <div class="invalid-feedback">
            Please provide a valid banner call to action title.
          </div>
        </div>

        <div class="form-group">
          <label for="bd_video_call_to_action_link">Call to Action Link:</label>
          <input type="text" v-model="banner.banner_data.call_to_action.link"
            :class="{ 'form-control': true, 'is-invalid': errors.has('bd_video_call_to_action_link') }"
            name="bd_video_call_to_action_link" id="bd_video_call_to_action_link" placeholder="Call to action link"
            v-validate="'required'" />
          <div class="invalid-feedback">
            Please provide a valid banner call to action link.
          </div>
        </div>

      </fieldset>

      <fieldset v-if="banner.banner_type == 'sign_up'">
        <legend>Banner Data</legend>

        <div class="form-group">
          <label for="bd_header_image">Header Image:</label>
          <input type="text" v-model="banner.banner_data.header_image"
            :class="{ 'form-control': true, 'is-invalid': errors.has('bd_header_image') }" name="bd_header_image"
            id="bd_header_image" placeholder="Banner Header" v-validate="'required'" />
          <div class="invalid-feedback">
            Please provide a valid header image url 1920x1080.
          </div>
        </div>
        <div class="form-group">
          <label for="bd_header">Header:</label>
          <input type="text" v-model="banner.banner_data.header"
            :class="{ 'form-control': true, 'is-invalid': errors.has('bd_header') }" name="bd_header" id="bd_header"
            placeholder="Banner Header" v-validate="'required'" />
          <div class="invalid-feedback">
            Please provide a valid banner header title.
          </div>
        </div>

        <div class="form-group">
          <label for="bd_description">Description:</label>
          <editor api-key="h6ymu878nq2dmit84wddqdm84to1x90pryg1iec6s96rph86" name="bd_description" id="bd_description" v-model="banner.banner_data.description"
            class="wysiwyg-editor" :init="{
              plugins:
                'wordcount link image fullscreen lists textcolor colorpicker a11ychecker advcode formatpainter linkchecker media mediaembed powerpaste tinydrive tinymcespellchecker',
            }"
            toolbar="fontselect | undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | table | fontsizeselect a11ycheck code formatpainter insertfile">
          </editor>
        </div>

        <div class="form-group">
          <label for="bd_video_embed_link">Video Embed Link:</label>
          <input type="text" v-model="banner.banner_data.video_embed_link"
            :class="{ 'form-control': true, 'is-invalid': errors.has('bd_video_embed_link') }"
            name="bd_video_embed_link" id="bd_video_embed_link" placeholder="Video Embed Link"
            v-validate="'required'" />
          <div class="invalid-feedback">
            Please provide a valid banner header title.
          </div>
        </div>

      </fieldset>
      <div class="form-group signup-carousel-buttons">
        <button class="btn btn-primary">Save</button>
      </div>
    </form>
  </div>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";
import BannerService from "../../services/BannerService";
import BannerTypes from "../../data/banner-types.json";
import HeaderPlacementOptions from "../../data/header-placement-options.json";

export default {
  methods: {
    saveChanges() {

      if (!isNaN(this.$route.params.id)) {
        BannerService.update(this.$route.params.id, this.banner)
          .then(response => {
            if (response.status === 200) {
              alert("Record updated successfully");
            }
          });
      } else if (this.$route.params.id == "add") {
        BannerService.create(this.banner)
          .then(response => {
            if (response.status === 200) {
              alert("Record created successfully");
            }
          });
      }

    }
  },

  data() {
    return {
      bannerTypes: BannerTypes,
      headerPlacementOptions: HeaderPlacementOptions,
      banner: {
        banner_data: {
          call_to_action: {}
        }
      }
    };
  },

  created() {
    if (!isNaN(this.$route.params.id)) {
      BannerService.findById(this.$route.params.id)
        .then(response => {
          let banner = response.data;
          if (!banner.banner_data.call_to_action) {
            banner.banner_data.call_to_action = {};
          }
          this.banner = banner;
        });
    } else if (this.$route.params.id !== "add") {
      alert("Invalid parameter.");
    }
  },

  components: {
    editor: Editor
  }
};
</script>
<style>
</style>
