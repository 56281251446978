<template>
  <div class="bioForm">
    <div class="note" v-html="note.body"></div>
  </div>
</template>

<style></style>

<script>
import NoteService from "../../services/NoteService";

export default {
  data: function () {
    return {
      note: {
        body: "",
      },
    };
  },

  created: function () {
    if (!isNaN(this.$route.params.noteId)) {
      NoteService.findByNoteId(this.$route.params.noteId).then((response) => {
        let data = response.data;
        this.note.body = data.body;
      });
    }
  },
};
</script>
