var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"changeUserPasswordForm"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.saveChanges.apply(null, arguments)}}},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"password"}},[_vm._v("Password:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"},{name:"validate",rawName:"v-validate",value:('required|min:7'),expression:"'required|min:7'"}],ref:"password",class:{
          'form-control': true,
          'is-invalid': _vm.errors.has('password'),
        },attrs:{"type":"password","name":"password","id":"password","placeholder":"Password"},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing)return;_vm.password=$event.target.value}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Please provide a valid password. Passwords must be alpha numeric and be at least 7 characters long. ")])]),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"password"}},[_vm._v("Password Confirmation:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password2),expression:"password2"},{name:"validate",rawName:"v-validate",value:('required|confirmed:password'),expression:"'required|confirmed:password'"}],class:{
          'form-control': true,
          'is-invalid': _vm.errors.has('password2'),
        },attrs:{"type":"password","name":"password2","id":"password_confirmation","placeholder":"Confirm Password"},domProps:{"value":(_vm.password2)},on:{"input":function($event){if($event.target.composing)return;_vm.password2=$event.target.value}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v("Please confirm password.")])]),_vm._m(0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group signup-carousel-buttons"},[_c('button',{staticClass:"btn btn-primary"},[_vm._v("Save")])])
}]

export { render, staticRenderFns }