import Vue from "vue";
import AccountService from "./AccountService";

export default {
  findById(id) {
    let apiMethodUrl = process.env.VUE_APP_ROOT_API + "/cec/purchases/" + id;
    let options = AccountService.getSessionHeaders();
    return Vue.axios.get(apiMethodUrl, options);
  },

  all() {
    let apiMethodUrl = process.env.VUE_APP_ROOT_API + "/cec/purchases";
    let options = AccountService.getSessionHeaders();
    return Vue.axios.get(apiMethodUrl, options);
  },
};
