import Vue from "vue";
import AccountService from "./AccountService";

export default {
  all() {
    let apiMethodUrl =
      process.env.VUE_APP_ROOT_API + "/assign-video-category?sort=BY_VIDEO";
    let options = AccountService.getSessionHeaders();

    return Vue.axios.get(apiMethodUrl, options);
  },

  create(videoCategory) {
    let apiMethodUrl =
      process.env.VUE_APP_ROOT_API + "/assign-video-category";
    let videoCategoryPayload = {};
    let options = AccountService.getSessionHeaders();
    videoCategoryPayload.video = videoCategory.videoId;
    videoCategoryPayload.category = videoCategory.categoryId;

    return Vue.axios.post(apiMethodUrl, videoCategoryPayload, options);
  },

  delete(video, category) {
    let apiMethodUrl =
      process.env.VUE_APP_ROOT_API + "/assign-video-category";
    let options = AccountService.getSessionHeaders();
    options.data = {
      video: video,
      category: category
    };
    

    return Vue.axios.delete(apiMethodUrl, options);
  },
};
