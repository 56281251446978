<template>
  <div class="pageForm">
    <form class="form-horizontal" v-on:submit.prevent="saveChanges">
      <div class="form-group">
        <label for="title">Description:</label>
        <input
          type="text"
          name="page_description"
          v-model="page.description"
          placeholder="Description of page content"
          class="form-control"
        />
      </div>
      <div v-show="pageType == 'object'">
        <div class="form-group" v-for="(field, key) in obj" v-bind:key="key">
          <label :for="key">{{ key }}</label>
          <input
            type="text"
            :name="key"
            v-model="obj[key]"
            class="form-control"
          />
        </div>
      </div>
      <div
        class="form-group"
        v-show="pageType == 'image' || pageType == 'video'"
      >
        <label for="title">Image/Video Link:</label>
        <input
          type="text"
          name="page_content"
          v-model="page.content"
          placeholder="Link to video or image"
          class="form-control"
        />
      </div>

      <div class="form-group" v-if="pageType == 'text'">
        <label for="body">Content:</label>
        <editor
          api-key="h6ymu878nq2dmit84wddqdm84to1x90pryg1iec6s96rph86"
          name="body"
          id="body"
          v-model="page.body"
          class="wysiwyg-editor"
          :init="{
            plugins:
              'wordcount link image fullscreen lists textcolor colorpicker',
          }"
        ></editor>
      </div>

      <div class="form-group signup-carousel-buttons">
        <button class="btn btn-primary">Save</button>
      </div>
    </form>
  </div>
</template>

<style></style>

<script>
import PageService from "../../services/PageService";
import Datepicker from "vuejs-datepicker";
import Editor from "@tinymce/tinymce-vue";

export default {
  data: function () {
    return {
      pageType: "",
      page: {
        description: "",
      },
      obj: {},
    };
  },

  created: function () {
    PageService.findById(this.$route.params.id).then((response) => {
      let data = response.data;

      this.pageType = data.type;
      this.page.description = data.description;

      if (data.type === "text") {
        this.page.body = data.content.copy;
      } else if (data.type === "object") {
        this.obj = data.content;
      } else {
        this.page.content = data.content;
      }
    });
  },

  components: {
    editor: Editor,
    datepicker: Datepicker,
  },

  methods: {
    saveChanges: function () {
      let content = "";
      let description = this.PageDescription;

      if (this.pageType === "text") {
        content = { copy: this.page.body };
      } else if (this.pageType === "object") {
        content = this.obj;
      } else {
        content = this.page.content;
      }
      let page = {
        description: this.page.description,
        content: content,
      };

      PageService.update(this.$route.params.id, page).then((response) => {
        alert("Page Updated");
      });
    },
  },
};
</script>
