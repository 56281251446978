<template>
  <div class="bioForm">
    <form class="form-horizontal" v-on:submit.prevent="saveChanges">
      <div class="form-group">
        <label for="title">Title:</label>
        <input
          type="text"
          name="title"
          v-model="note.title"
          placeholder="Title"
          class="form-control"
        />
      </div>

      <div class="form-group">
        <label for="description">Description:</label>
        <textarea
          class="form-control"
          rows="4"
          v-model="note.description"
        ></textarea>
      </div>

      <div class="form-group">
        <label for="body">Video:</label>
        <select class="form-control" v-model="note.video">
          <option
            v-for="video in videos"
            v-bind:key="video.id"
            :value="video.id"
          >
            {{ video.title }}
          </option>
        </select>
      </div>

      <div class="form-group">
        <label for="body">Body:</label>
        <editor
          api-key="h6ymu878nq2dmit84wddqdm84to1x90pryg1iec6s96rph86"
          name="body"
          id="body"
          v-model="note.body"
          class="wysiwyg-editor"
          :init="{
            plugins:
              'wordcount link image fullscreen lists textcolor colorpicker a11ychecker advcode formatpainter linkchecker media mediaembed powerpaste tinydrive tinymcespellchecker',
          }"
          toolbar="fontselect | undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | forecolor backcolor | bullist numlist outdent indent | table | fontsizeselect a11ycheck code formatpainter insertfile"
        ></editor>
      </div>

      <div class="form-group signup-carousel-buttons">
        <button class="btn btn-primary">Save</button>
      </div>
    </form>
  </div>
</template>

<style>
.wysiwyg-editor {
  height: 400px;
}
</style>

<script>
import NoteService from "../../services/NoteService";
import VideoService from "../../services/VideoService";
import Editor from "@tinymce/tinymce-vue";

export default {
  data: function () {
    return {
      videos: [],
      note: {
        title: "",
        description: "",
        video: 0,
        body: "",
      },
    };
  },

  components: {
    editor: Editor,
  },

  created: function () {
    VideoService.all().then((response) => {
      this.videos = response.data;
    });

    if (!isNaN(this.$route.params.noteId)) {
      NoteService.findByNoteId(this.$route.params.noteId).then((response) => {
        let data = response.data;
        this.note.title = data.title;
        this.note.description = data.description;
        this.note.body = data.body;
        this.note.video = data.video;
      });
    }
  },

  methods: {
    saveChanges: function () {
      if (!isNaN(this.$route.params.noteId)) {
        NoteService.update(this.$route.params.noteId, this.note).then(
          (response) => {
            alert("Note Updated");
          }
        );
      } else if (this.$route.params.noteId == "add") {
        NoteService.create(this.note).then((response) => {
          alert("Note created.");
        });
      }
    },
  },
};
</script>
