<template>
  <div class="cloudfront-link-generate-form">
    <div
      class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom"
    >
      <h1 class="h2">Cloudfront URL conversion tool</h1>
    </div>
    <form class="row" v-on:submit.prevent="generateCloudfrontCDNLink">
      <div class="form-group col-9">
        <label for="body">S3 asset URL:</label>
        <input  class="form-control" v-model="S3URL" />
      </div>
      <div class="form-group col-3">
        <label for="body">S3 Bucket:</label>
        <select class="form-control" v-model="selectedS3Bucket">
            <option
            selected
            value=""
          >
          Select Bucket
          </option>
          <option
            v-for="bucket in s3Buckets"
            v-bind:key="bucket.name"
            :value="bucket.name"
          >
            {{ bucket.name }}
          </option>
        </select>
      </div>
      <div class="form-group col-3">
        <button class="btn btn-primary">Generate CDN Cloudfront Link</button>
      </div>
    </form>
    <div class="mt-4 row">
        <div class="col-12 convertedURl">
            {{ cloudFrontUrl }}
        </div>
    </div>
  </div>
</template>

<style></style>

<script>

export default {
  data: function () {
    return {
      s3Buckets: [
        {
            name: 'fitmotivation',
            cloudFrontMapUrl: 'd34zmiz3wv00g9.cloudfront.net'
        },
        {
            name: 'fitmotivationsrv',
            cloudFrontMapUrl: 'd18cfrkvlsavt2.cloudfront.net'
        },
        {
            name: 'poolfit',
            cloudFrontMapUrl: 'dj2egt95vsmn2.cloudfront.net'
        },
        {
            name: 'poolfitvideos',
            cloudFrontMapUrl: 'd20q0iz7grsnza.cloudfront.net'
        }
      ],
      selectedS3Bucket: '',
      S3Base: '',
      S3URL: '',
      cloudFrontBase: '',
      cloudFrontUrl: ''
    };
  },

  watch: {
    S3URL: function() {
      this.autoSelectBucket();
    }
  },

  methods: {
    generateCloudfrontCDNLink: function () {
      console.log('get ready to convert...')

      this.cloudFrontUrl = this.S3URL.replace(this.S3Base, this.cloudFrontBase);
    },

    autoSelectBucket: function () {

        if (this.S3URL) {
            const S3URLSplit = this.S3URL.split( '/' );
            const S3URLBase = S3URLSplit[2];

            this.S3Base = S3URLBase;
            this.cloudFrontUrl = "";

            console.log('S3URLBase Base >>>>>>>>>>>>>>>>>>', S3URLBase)

            switch (S3URLBase) {
                case 'fitmotivation.s3.amazonaws.com':
                    this.selectedS3Bucket = 'fitmotivation';
                    this.cloudFrontBase = 'd34zmiz3wv00g9.cloudfront.net';
                    break;
                case 'fitmotivationsrv.s3.amazonaws.com':
                    this.selectedS3Bucket = 'fitmotivationsrv';
                    this.cloudFrontBase = 'd18cfrkvlsavt2.cloudfront.net';
                    break;
                case 'poolfit.s3.amazonaws.com':
                    this.selectedS3Bucket = 'poolfit';
                    this.cloudFrontBase = 'dj2egt95vsmn2.cloudfront.net';
                    break;
                case 'poolfitvideos.s3.amazonaws.com':
                    this.selectedS3Bucket = 'poolfitvideos';
                    this.cloudFrontBase = 'd20q0iz7grsnza.cloudfront.net';
                    break;
            
                default:
                    break;
            }
        }

    }
  },
};
</script>
