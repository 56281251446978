import Vue from "vue";
import AccountService from "./AccountService";

export default {
  all() {
    let apiMethodUrl = process.env.VUE_APP_ROOT_API + "/graphql";
    let options = AccountService.getSessionHeaders();
    let statsPayload = {};

    statsPayload.query = `{
            UserCount
            Plans {
              plan
              plan_count
            }
            Countries {
              country
              country_count
            }
            TotalCountries
            Quizes {
              totalPurchased
              totalTaken
              passed
              failed
              incomplete
              neverAttempted
              valueBreakdown {
                priceLevel
                purchaseCount
              }
              popularity {
                title
                purchaseCount
              }
            }
            AudioDownloads {
              byUrl {
                url
                downloads
              }
            }
            Notes {
              totalOriginals
              totalEdited
              editsPerVideo {
                videoTitle
                edits
              }
              editsPerUser {
                userId
                edits
              }
            }
          }`;

    return Vue.axios.post(apiMethodUrl, statsPayload, options);
  },


  getCustomerReferralStats(startDate = undefined, endDate = undefined) {
    let apiMethodUrl = process.env.VUE_APP_ROOT_API + "/stats/customer/referrals";
    let options = AccountService.getSessionHeaders();
    let dataOptions = {
      start_date: startDate,
      end_date: endDate
    };

    return Vue.axios.post(apiMethodUrl, dataOptions, options);
  },

  getCustomerCancelledStats(month = undefined, year = undefined) {
    let apiMethodUrl = process.env.VUE_APP_ROOT_API + "/stats/customer/cancelled";
    let options = AccountService.getSessionHeaders();
    let dataOptions = {
      month: month,
      year: year
    };

    return Vue.axios.post(apiMethodUrl, dataOptions, options);
  },

  getQuizzesFiltered(month = 0, year = 2023) {
    let apiMethodUrl = process.env.VUE_APP_ROOT_API + "/stats/customer/quizzesfiltered";
    let options = AccountService.getSessionHeaders();
    let dataOptions = {
      month: month,
      year: year
    };

    return Vue.axios.post(apiMethodUrl, dataOptions, options);
  }
};
