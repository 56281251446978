import Vue from "vue";
import AccountService from "./AccountService";

export default {
  all() {
    let apiMethodUrl = process.env.VUE_APP_ROOT_API + "/assign-video-bio";
    let options = AccountService.getSessionHeaders();

    return Vue.axios.get(apiMethodUrl, options);
  },

  create(videoBio) {
    let apiMethodUrl =
      process.env.VUE_APP_ROOT_API + "/assign-video-bio";
    let videoBioPayload = {};
    let options = AccountService.getSessionHeaders();
    videoBioPayload.video = videoBio.videoId;
    videoBioPayload.instructor = videoBio.instructorId;

    return Vue.axios.post(apiMethodUrl, videoBioPayload, options);
  },

  delete(video, instructor) {
    let apiMethodUrl =
      process.env.VUE_APP_ROOT_API + "/assign-video-bio";
    let options = AccountService.getSessionHeaders();
    options.data = {
      instructor: instructor,
      video: video
    };

    return Vue.axios.delete(apiMethodUrl, options);
  },
};
