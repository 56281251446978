import Vue from "vue";
import AccountService from "./AccountService";

export default {
  all() {
    let apiMethodUrl =
      process.env.VUE_APP_ROOT_API + "/notes?all=1&templates_only=true";
    let options = AccountService.getSessionHeaders();
    return Vue.axios.get(apiMethodUrl, options);
  },

  findByVideoId(id) {
    let apiMethodUrl = process.env.VUE_APP_ROOT_API + "/notes/videoId/" + id;
    let payload = {};
    let options = AccountService.getSessionHeaders();
    return Vue.axios.post(apiMethodUrl, payload, options);
  },

  findByUserId(id) {
    let apiMethodUrl = process.env.VUE_APP_ROOT_API + "/notes/userId/" + id;
    let payload = {};
    let options = AccountService.getSessionHeaders();
    return Vue.axios.post(apiMethodUrl, payload, options);
  },

  findByNoteId(id) {
    let apiMethodUrl = process.env.VUE_APP_ROOT_API + "/notes?noteId=" + id;
    let options = AccountService.getSessionHeaders();
    return Vue.axios.get(apiMethodUrl, options);
  },

  findTemplateByVideoId(videoId) {
    let apiMethodUrl =
      process.env.VUE_APP_ROOT_API + "/notes/templateForVideo/" + videoId;
    let payload = {};
    let options = AccountService.getSessionHeaders();
    return Vue.axios.post(apiMethodUrl, payload, options);
  },

  create(note) {
    let apiMethodUrl = process.env.VUE_APP_ROOT_API + "/notes";
    let payload = {};
    let options = AccountService.getSessionHeaders();

    payload.title = note.title;
    payload.description = note.description;
    payload.video = note.video;
    payload.body = note.body;

    return Vue.axios.post(apiMethodUrl, payload, options);
  },

  update(id, note) {
    let apiMethodUrl = process.env.VUE_APP_ROOT_API + "/notes/" + id;
    let payload = {};
    let options = AccountService.getSessionHeaders();

    payload.title = note.title;
    payload.description = note.description;
    payload.video = note.video;
    payload.body = note.body;
    payload.id = id;

    return Vue.axios.patch(apiMethodUrl, payload, options);
  },

  delete(id) {
    let apiMethodUrl = process.env.VUE_APP_ROOT_API + "/notes/" + id;
    let options = AccountService.getSessionHeaders();

    return Vue.axios.delete(apiMethodUrl, options);
  },

  download(id) {
    let apiMethodUrl = process.env.VUE_APP_ROOT_API + "/notes/pdf/" + id;
    let options = AccountService.getSessionHeaders();

    options.responseType = "arraybuffer";

    return Vue.axios.get(apiMethodUrl, options);
  },
};
