<template>
  <div class="testimonials">
    <div class="modal fade" id="deleteRecordModal" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              Confirm you would like to delete this user!
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>Are you sure you would like to delete this user?</p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              @click="saveDeleteRecord"
            >
              Yes
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              No
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row header">
      <div class="col-xs-6 col-md-6 header-group">
        <h2>Users ({{ totalEnabled }} Active)</h2>
      </div>
      <div class="col-xs-6 col-md-6 search">
        <input
          class="form-control form-control-sm w-100"
          type="text"
          placeholder="Search"
          aria-label="Search"
          v-model="search"
        />
      </div>
    </div>
    <div class="customerCounts row">
      <ul>
        <li>Monthly {{ monthly }}</li>
        <li>Annual {{ annual }}</li>
        <li>Basic Monthly: {{ counts.basic_monthly.plan_total }}</li>
        <li>Premium Monthly: {{ counts.premium_monthly.plan_total }}</li>
        <li>Basic Annual: {{ counts.basic_annual.plan_total }}</li>
        <li>Premium Annual: {{ counts.premium_annual.plan_total }}</li>
      </ul>
    </div>
    <div class="filters row mt-2 ml-2">
      <div class="btn-group" role="group" aria-label="Customer Status Filter">
        <button
          type="button"
          :disabled="isLoading"
          class="btn btn-primary"
          :class="selectedAll ? 'active' : ''"
          @click="getFilteredCustomers('all')"
        >
          All
        </button>
        <button
          type="button"
          :disabled="isLoading"
          class="btn btn-primary"
          :class="selectedActive ? 'active' : ''"
          @click="getFilteredCustomers('active')"
        >
          Active
        </button>
        <button
          type="button"
          :disabled="isLoading"
          class="btn btn-primary"
          :class="selectedPending ? 'active' : ''"
          @click="getFilteredCustomers('pending')"
        >
          Pending
        </button>
        <button
          type="button"
          :disabled="isLoading"
          class="btn btn-primary"
          :class="selectedDisabled ? 'active' : ''"
          @click="getFilteredCustomers('disabled')"
        >
          Disabled
        </button>
        <button
          type="button"
          :disabled="isLoading"
          class="btn btn-primary"
          :class="selectedDelinquent ? 'active' : ''"
          @click="getFilteredCustomers('delinquent')"
        >
          Delinquent
        </button>
      </div>
    </div>
    <div class="d-flex justify-content-center" v-if="isLoading">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <table
      class="table-striped table table-hover table-condensed mt-4"
      v-if="!isLoading"
    >
      <tr>
        <th>Email</th>
        <th>Fullname</th>
        <th>Country</th>
        <th>Stripe ID</th>
        <th>Plan</th>
        <th>Last Login</th>
        <th>Enabled</th>
        <th scope="col">Subscribed On</th>
        <th
          v-if="selectedDisabled"
          @click="sortColumn('cancelled_on', sortedState)"
        >
          Cancelled On <i>&#8597;</i>
        </th>
        <th scope="col">Created On</th>
        <th>&nbsp;</th>
      </tr>
      <tr v-for="(user, index) in filteredUsers" v-bind:key="user.id">
        <td>{{ user.email }}</td>
        <td>
          {{ user.fullname }}
          <span
            v-if="showNewBadge(selectedActive, user.subscribed_on)"
            class="badge badge-primary"
            >New</span
          >
        </td>
        <td>{{ user.country }}</td>
        <td>{{ user.stripe_customer_id }}</td>
        <td>{{ user.plan }}</td>
        <td>{{ user.last_login_time | dateformat }}</td>
        <td>
          <span v-if="user.enabled == 1">Yes</span>
          <span v-if="user.enabled == 0">No</span>
        </td>
        <td>{{ user.subscribed_on | dateformat }}</td>
        <td v-if="selectedDisabled">{{ user.cancelled_on | dateformat }}</td>
        <td>{{ user.created_on | dateformat }}</td>
        <td style="width: 200px">
          <router-link :to="{ path: '/users/password/' + user.id }"
            ><button class="btn btn-success btn-xs">
              Change Password
            </button></router-link
          >
          <router-link :to="{ path: '/users/' + user.id }"
            ><button class="btn btn-success btn-xs">Edit</button></router-link
          >
          <button
            class="btn btn-danger btn-xs"
            @click="confirmDeleteRecord($event, user.id, index)"
          >
            Delete
          </button>
        </td>
      </tr>
    </table>
  </div>
</template>

<style></style>

<script>
import UserService from "../../services/UserService";

export default {
  data: function () {
    return {
      search: "",
      users: [],
      subscriberCount: 0,
      deleteRecord: {
        index: 0,
        id: 0,
      },
      counts: {
        basic_monthly: 0,
        basic_annual: 0,
        premium_monthly: 0,
        premium_annual: 0,
      },
      isLoading: true,
      selectedAll: false,
      selectedActive: false,
      selectedDisabled: false,
      selectedPending: false,
      selectedDelinquent: false,
      selectedFilter: "",
      sortedState: "ASC",
    };
  },

  created: function () {
    this.setHeaderStats();

    if (Object.hasOwn(this.$route.query, "filter")) {
      this.getFilteredCustomers(this.$route.query.filter);
    } else {
      this.getFilteredCustomers("all");
    }
  },

  methods: {
    sortColumn: function (column, order) {
      this.sortedState.toLowerCase() === "asc"
        ? (this.sortedState = "DESC")
        : (this.sortedState = "ASC");

      this.isLoading = true;

      UserService.filteredCustomers(
        this.selectedFilter,
        column,
        this.sortedState
      ).then((response) => {
        this.users = response.data.customers;

        this.setFilteredButtonState(this.selectedFilter);

        this.subscriberCount = response.data.customers.filter((customer) => {
          return customer.enabled === 1;
        }).length;
      });
    },

    confirmDeleteRecord: function (event, id, index) {
      this.deleteRecord = {
        id: id,
        index: index,
      };

      $("#deleteRecordModal").modal({});
    },

    saveDeleteRecord: function () {
      UserService.delete(this.deleteRecord.id).then((response) => {
        //$('#deleteRecordModal').modal('hide');
        $("[data-dismiss=modal]").trigger({ type: "click" });
        this.users.splice(this.deleteRecord.index, 1);
      });
    },

    setHeaderStats: function () {
      UserService.all().then((response) => {
        this.isLoading = false;

        this.subscriberCount = response.data.customers.filter((customer) => {
          return customer.enabled === 1;
        }).length;
      });

      UserService.getPlanCounts().then((resp) => {
        this.counts["basic_monthly"] = resp.data.find(
          (plan) => plan.plan_name === "fmbasic01"
        );
        this.counts["basic_annual"] = resp.data.find(
          (plan) => plan.plan_name === "fmbasic02"
        );
        this.counts["premium_monthly"] = resp.data.find(
          (plan) => plan.plan_name === "fmpremium01"
        );
        this.counts["premium_annual"] = resp.data.find(
          (plan) => plan.plan_name === "fmpremium02"
        );
      });
    },

    getFilteredCustomers: function (filter) {
      this.isLoading = true;

      UserService.filteredCustomers(filter).then((response) => {
        this.users = response.data.customers;

        this.setFilteredButtonState(filter);
        this.setFilteredURLParams({ filter: filter });

        this.subscriberCount = response.data.customers.filter((customer) => {
          return customer.enabled === 1;
        }).length;
      });
    },

    setFilteredButtonState: function (filter) {
      this.selectedActive = false;
      this.selectedDisabled = false;
      this.selectedPending = false;
      this.selectedDelinquent = false;
      this.selectedAll = false;

      switch (filter) {
        case "active":
          this.selectedActive = true;
          this.selectedFilter = "active";
          break;
        case "disabled":
          this.selectedDisabled = true;
          this.selectedFilter = "disabled";
          break;
        case "pending":
          this.selectedPending = true;
          this.selectedFilter = "pending";
          break;
        case "delinquent":
          this.selectedDelinquent = true;
          this.selectedFilter = "delinquent";
          break;
        default:
          this.selectedAll = true;
          this.selectedFilter = "";
          break;
      }

      this.isLoading = false;
    },

    setFilteredURLParams: function (filter) {
      history.pushState(
        {},
        null,
        this.$route.path +
          "?" +
          Object.keys(filter)
            .map((key) => {
              return (
                encodeURIComponent(key) + "=" + encodeURIComponent(filter[key])
              );
            })
            .join("&")
      );
    },

    showNewBadge(activeFilter, date) {
      const currentDate = new Date();
      const subscribedDate = new Date(date);
      const dayMillisecondsConversion = 1000 * 60 * 60 * 24;

      if (
        activeFilter &&
        date &&
        (currentDate.getTime() - subscribedDate.getTime()) /
          dayMillisecondsConversion <
          30
      ) {
        return true;
      }

      return false;
    },
  },

  computed: {
    monthly() {
      return (
        parseInt(this.counts.basic_monthly.plan_total) +
        parseInt(this.counts.premium_monthly.plan_total)
      );
    },

    totalEnabled() {
      return this.monthly + this.annual;
    },

    annual() {
      return (
        parseInt(this.counts.basic_annual.plan_total) +
        parseInt(this.counts.premium_annual.plan_total)
      );
    },

    filteredUsers() {
      return this.users.filter((item) => {
        let searchPhrase = this.search.toLowerCase();

        return (
          item.fullname.toLowerCase().indexOf(searchPhrase) > -1 ||
          item.email.toLowerCase().indexOf(searchPhrase) > -1
        );
      });
    },
  },
};
</script>
